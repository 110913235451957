import { Component, OnInit, OnChanges, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
	OrganisationDto,
} from 'app/services';
import { ClientEditBaseComponent } from '../client-edit-base.component';

import { IForm, IEditComponent } from 'app/interfaces';
import { CasesService }  from 'app/cases/cases.service';
import { TextComponent } from 'app/shared/forms/text.component';

@Component({
	selector: 'pp-client-edit-details',
	templateUrl: './client-edit-details.component.html',
	styleUrls: ['./client-edit-details.component.scss']
})
export class ClientEditDetailsComponent extends ClientEditBaseComponent 
	implements OnInit, OnChanges, AfterViewInit, IForm, IEditComponent {
	private changed: boolean;
	form: FormGroup;
	organisation: OrganisationDto;

	@ViewChild('anonRef', { static: true }) anonRef: TextComponent;

	constructor(
		private fb: FormBuilder,
		private service: CasesService,
		private cdRef: ChangeDetectorRef,
	) {
		super();
		this.buildForm();
	}

	getForm() {
		return this.form;
	}

	hasChanges() {
		return this.changed;
	}

	markClean() {
		this.changed = false;
	}

	ngOnInit() {
		this.organisation = this.service.currentLoadDto.organisation;
	}

	ngOnChanges(changes) {
		if (this.client) {
			this.form.patchValue({
				anonymousReference: this.client.anonymousReference
			});

			this.form.controls.anonymousReference.valueChanges.subscribe(v => {
				this.client.anonymousReference = v;
			});

			this.anonRef.setFocus();
		}
	}

	ngAfterViewInit() {
		this.anonRef.setFocus();
		this.cdRef.detectChanges();
	}

	organisationChanged(e) {
		this.changed = true;
	}

	private buildForm() {
		this.form = this.fb.group({
			anonymousReference: ['', Validators.required]
		});
	}
}
