import { Component, OnChanges, Input, forwardRef, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { MatDatepicker } from '@angular/material';
import * as moment from 'moment';

import { BaseComponent } from './base-component';

let identifier = 0;

@Component({
	selector: 'pp-date',
	template: `
	<div class="form-component">
		<div fxLayout="row" fxLayoutAlign="start center">
			<mat-form-field fxFlex>
				<input
					#dateInput
					matInput
					[required]="required"
					[disabled]="isDisabled"
					[placeholder]="placeholder"
					[(ngModel)]="inputValue"
					(keydown.enter)="togglePicker()"
					(click)="togglePicker()"
					(blur)="inputBlur()"
					(focus)="inputFocus()"
					(keydown)="inputKeydown($event)">
				<input matInput
					#input
					hidden="true"
					[id]="identifier"
					[placeholder]="placeholder"
					[disabled]="isDisabled"
					[(ngModel)]="value"
					(blur)="onBlur()"
					[matDatepicker]="picker">
				<mat-datepicker-toggle #calendarButton tabIndex="-1" matSuffix [for]="picker"></mat-datepicker-toggle>
			</mat-form-field>
			<mat-datepicker #picker></mat-datepicker>
		</div>
 		<div class="validation-message" *ngIf="control?.touched && control.hasError('required')">{{ label }} is required</div>
	</div>`,
	styleUrls: ['./date.component.scss'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateComponent), multi: true },
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => DateComponent), multi: true }
	]
})
export class DateComponent extends BaseComponent<Date> implements OnChanges {
	inputValue: string;

	@Input() label: string;
	@Input() placeholder = '';

	public identifier = `date-${identifier++}`;

	@ViewChild('input', { static: true }) inputEl: ElementRef;
	@ViewChild('dateInput', { static: true }) dateInputEl: ElementRef;
	@ViewChild('picker', { static: true }) picker: MatDatepicker<moment.Moment>;

	constructor(
		private cdRef: ChangeDetectorRef,
	) {
		super();
	}

	onBlur() {
		this.touch();
	}

	ngOnChanges(inputs) {
		if (!this.placeholder) {
			this.placeholder = this.label || '';
		}
	}

	inputKeydown(e: KeyboardEvent) {
		switch (e.keyCode) {
			case 36:
			case 35:
			case 36:
			case 39:
			case 16:
			case 9:
				return;
			default:
				break;
		}

		switch (e.key) {
			case 'Backspace':
			case 'Delete':
			case 'Enter':
				return;
			case '.':
			case '-':
			case '/':
			case ' ':
			case '0':
			case '1':
			case '2':
			case '3':
			case '4':
			case '5':
			case '6':
			case '7':
			case '8':
			case '9':
				return;
			default:
				e.preventDefault();
				e.stopPropagation();
				break;
		}
	}

	inputFocus() {
		this.picker.close();
		this.formatInputValue(true);
		setTimeout(() => {
			this.dateInputEl.nativeElement.select();
		});

		this.cdRef.detectChanges();
	}

	inputBlur() {
		this.onBlur();

		if (!this.inputValue) {
			this.value = null;
			return;
		}

		let val = this.inputValue;
		// Normalise to replace . or - or space with /
		val = val.replace(/[.-\s]/g, '/');

		// Liberal interpretation to allow just the day of the current month,
		// or day/month of current year, or full date format.
		let m = moment(val, ['D/M/YYYY', 'D/M', 'D'], false);
		if (m.isValid()) {
			this.value = m.toDate();
			this.formatInputValue();
		}
	}

	formatInputValue(focus: boolean = false) {
		const format = focus ? 'D/M/YYYY' : 'ddd D MMM, YYYY';
		if (!this.value) {
			this.inputValue = '';
		} else {
			this.inputValue = moment(this.value).format(format);
		}
	}

	onSetValue() {
		this.formatInputValue();
	}

	writeValue(value: Date) {
		super.writeValue(value);
		if (value) {
			this.formatInputValue();
		} else {
			this.inputValue = '';
		}
	}

	setFocus() {
		this.dateInputEl.nativeElement.focus();
		this.dateInputEl.nativeElement.select();
	}

	togglePicker() {
		this.picker.open();
	}
}
