import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
	selector: '[ppValidateEqual][formControlName],[ppValidateEqual][formControl],[ppValidateEqual][ngModel]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidatorDirective), multi: true }
	]
})
export class EqualValidatorDirective implements Validator {
	constructor(
		@Attribute('ppValidateEqual') public validateEqual: string,
		@Attribute('reverse') public reverse: boolean) {
	}

	validate(c: AbstractControl): { [key: string]: any } {
		if (!c || !c.root) {
			return null;
		}

		// self value
		const v = c.value;

		// Get the other control
		const e = c.root.get(this.validateEqual);

		if (e && v !== e.value && !this.reverse) {
			return {
				validateEqual: true
			};
		}

		// Value equal and reverse
		if (e && v === e.value && this.reverse) {
			if (e.errors) {
				delete e.errors['validateEqual'];
			}
			if (e.errors && !Object.keys(e.errors).length) {
				e.setErrors(null);
			}
		}

		// Values not equal and reverse.
		if (e && v !== e.value && this.reverse) {
			e.setErrors({
				validateEqual: true
			});
		}

		return null;
	}
}
