import * as moment from 'moment';

/**
 * Converts an ISO8601 format date (and time) to a GMT moment with the
 * same date and time.
 *
 * @export
 * @param {string} iso The ISO8601-format date/time.
 * @returns {moment.Moment} The moment.Moment
 */
export function isoStringToAmbiguousMoment(iso: string): moment.Moment {
	return dateToAmbiguousMoment(moment(iso).toDate());
}

/**
 * Converts a (local) date (and time) to a GMT moment for use with the
 * FullCalendar component. E.g. 12 September 15:00 NZ time will be
 * converted to 12 Septenber 15:00 GMT.
 *
 * Calling toString() will return "Tue Sep 12 2017 15:00:00 GMT+0000"
 *
 * @export
 * @param {Date} date The date to convert.
 * @returns {moment.Moment} The resulting moment.Moment.
 */
export function dateToAmbiguousMoment(date: Date): moment.Moment {
	return moment(date).utcOffset(0, true).utc();
}

/**
 * Applies the UTC offset to the provided date to create a UTC equivalent
 * Date and time. When serialized (by the toJSON function) it will
 * arrive at the server appearing to be a UTC DateTime with the same
 * apparent (wall-clock) date and time as the provided date.
 *
 * The server will then call toUnspecified() on it to convert it to an
 * Unspecified Kind thus appearing to be the correct date and time as was selected
 * on the client.
 *
 * E.g. 12 September 08:00 NZ time will be converted to 11 Septenber 20:00 GMT.
 * Calling toJSON() would give '2017-09-12T08:00:00.000Z'
 *
 * @export
 * @param {Date} date The date to convert.
 * @returns {Date} The resulting UTC Date.
 */
export function toUnspecified(date: Date): Date {
	const tzOffset = date.getTimezoneOffset(),
		m = moment(date).subtract(tzOffset, 'minutes');
	return m.toDate();
}
