import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatExpansionPanel } from '@angular/material';

import {
	ConfigService,
	ProfessionalDto,
	BookingResource,
} from 'app/services';

@Component({
	selector: 'pp-practice-navigator',
	templateUrl: './practice-navigator.component.html',
	styleUrls: ['./practice-navigator.component.scss']
})
export class PracticeNavigatorComponent implements OnInit, AfterViewInit {
	private panels: MatExpansionPanel[];

	// Flag to ensure that the openPanel and closePanel functions only operate when triggered by the user.
	private ignoreOpenClose: boolean;

	professionals: ProfessionalDto[];
	offices: BookingResource[];

	// CSS height calculation for each panel - 100% less 2 x 64px header heights.
	calcFlexHeight = 'calc(100% - 128px)';

	@ViewChild('panelPracticeDiaries', { static: true }) panelPracticeDiaries: MatExpansionPanel;
	@ViewChild('panelProfessionalDiaries', { static: true }) panelProfessionalDiaries: MatExpansionPanel;
	@ViewChild('panelProfessionalAvailabilities', { static: true }) panelProfessionalAvailabilities: MatExpansionPanel;

	constructor(
		private config: ConfigService,
		private router: Router,
	) {	}

	ngOnInit() {
		const userConfig = this.config.config;
		const practiceConfig = this.config.practiceConfig;

		this.professionals = userConfig.practiceProfessionals;
		this.offices = practiceConfig.practiceBookingResources;
	}

	ngAfterViewInit() {
		this.panels = [
			this.panelPracticeDiaries,
			this.panelProfessionalDiaries,
			this.panelProfessionalAvailabilities
		];
	}

	gotoOffice(e: Event, office: BookingResource) {
		e.stopPropagation();
		this.router.navigate(['/appointments', { type: 'office', id:  office.id }]);
	}

	gotoProfessionalDiary(e: Event, professional: ProfessionalDto) {
		e.stopPropagation();
		this.router.navigate(['/appointments', { type: 'professional', id: professional.id }]);
	}

	gotoProfessionalAvailability(e: Event, professional: ProfessionalDto) {
		e.stopPropagation();
		this.router.navigate(['/availability', { 'id': professional.id }]);
	}

	openPanel(panel: MatExpansionPanel) {
		if (!this.panels || this.ignoreOpenClose) {
			return;
		}

		// When one panel opens, close all others.
		let otherPanels = this.panels.filter(p => p !== panel);

		this.ignoreOpenClose = true;
		otherPanels.forEach(p => p.close());
		this.ignoreOpenClose = false;
	}

	closePanel(panel: MatExpansionPanel) {
		if (!this.panels || this.ignoreOpenClose) {
			return;
		}

		// When a panel closes, open the next one, cycling around.
		let panelIndex = this.panels.indexOf(panel);
		let nextPanelIndex = (panelIndex + 1) % this.panels.length;
		let nextPanel = this.panels[nextPanelIndex];

		this.ignoreOpenClose = true;
		nextPanel.open();
		this.ignoreOpenClose = false;
	}
}
