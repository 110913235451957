import { Component } from '@angular/core';
import { ConfigService } from '../services';

@Component({
	selector: 'pp-professional-dashboard',
	templateUrl: './professional-dashboard.component.html',
	styleUrls: ['./professional-dashboard.component.scss']
})
export class ProfessionalDashboardComponent {
	isEmployee: boolean;

	constructor(
		private config: ConfigService,
	) {
		this.isEmployee = config.config.isEmployee;
	}
}
