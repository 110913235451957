import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
	DashboardService,
	OpenActivityHeadersCountDto,
	CommonService,
} from 'app/services';

import { BaseViewComponent } from 'app/shared/base-view/base-view-component';
@Component({
	selector: 'pp-active-cases',
	templateUrl: './active-cases.component.html',
	styleUrls: ['./active-cases.component.scss']
})
export class ActiveCasesComponent extends BaseViewComponent implements OnInit {
	activityHeaders: OpenActivityHeadersCountDto;

	constructor(
		common: CommonService,
		private router: Router,
		private dashboardService: DashboardService
	) {
		super(common);
	}

	ngOnInit() {
		this.refresh();
	}

	refresh() {
		this.loading = true;
		this.dashboardService.getDashboardOpenActivityHeadersCount()
			.subscribe(result => this.handleDashboardData(result), err => this.handleError(err, 'loading cases'));
	}

	private handleDashboardData(data: OpenActivityHeadersCountDto) {
		this.activityHeaders = data;
		this.loading = false;
	}
}
