import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'pp-modal-dialog-footer',
	templateUrl: './modal-dialog-footer.component.html',
	styleUrls: ['./modal-dialog-footer.component.scss']
})
export class ModalDialogFooterComponent implements OnInit {
	@Input() okCaption: string = 'OK';
	@Input() cancelCaption: string = 'Cancel';
	@Input() disabled: boolean = false;
	@Input() asClose: boolean = false;
	@Input() cancelOnly: boolean = false;
	@Output() closed = new EventEmitter<void>();
	@Output() cancelled = new EventEmitter<void>();

	ngOnInit() {
		if (this.asClose) {
			this.cancelCaption = 'Close';
		}
	}

	close() {
		this.closed.emit();
	}

	cancel() {
		if (this.asClose) {
			this.close();
			return;
		}
		this.cancelled.emit();
	}
}
