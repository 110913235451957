import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseComponent } from './base-component';

let identifier = 0;

@Component({
	selector: 'pp-button-toggle',
	template: `
	<mat-button-toggle
		fxFlex
		mat-raised-button
		[id]="identifier"
		[checked]="value"
		[disabled]="isDisabled"
		[ngClass]="{ 'toggle-disabled': isDisabled }"
		(change)="valueChanged($event)">
		{{ label }}
	</mat-button-toggle>
`,
	styleUrls: ['./button-toggle.component.scss'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ButtonToggleComponent), multi: true }
	]
})
export class ButtonToggleComponent extends BaseComponent<boolean> {
	@Input() label: string;

	public identifier = `button-toggle-${identifier++}`;

	valueChanged(ev: Event) {
		this.value = !this.value;
	}
}
