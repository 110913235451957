export enum ChangeType {
	Update,
	Delete
}

export abstract class ChangeNotificationBase {
	instance: any;
	id: number;
	changeType: ChangeType;

	static update<T>(inst: T): ChangeNotification<T> {
		return <ChangeNotification<T>> {
			changeType: ChangeType.Update,
			instance: inst
		};
	}

	static delete<T>(id: number): ChangeNotification<T> {
		return <ChangeNotification<T>> {
			changeType: ChangeType.Delete,
			id: id
		};
	}
}

export class ChangeNotification<T> extends ChangeNotificationBase {
	instance: T;
}
