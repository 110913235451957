import { Component, Input, OnInit } from '@angular/core';
import { RiskOptionValueDto } from 'app/services';
import * as _ from 'lodash';

@Component({
	selector: 'pp-risk-profile-factor',
	templateUrl: './risk-profile-factor.component.html',
	styleUrls: ['./risk-profile-factor.component.scss'],
})
export class RiskProfileFactorComponent implements OnInit {
	@Input() type: string;
	@Input() factors: RiskOptionValueDto[];

	atRisk: boolean;

	ngOnInit() {
		this.atRisk = _.some(this.factors, (f) => f.isAtRisk);
	}
}
