import { Component, OnInit, Input } from '@angular/core';
import { RiskProfileDto, RiskOptionValueDto } from 'app/services';
import * as _ from 'lodash';

@Component({
	selector: 'pp-risk-profile-display',
	templateUrl: './risk-profile-display.component.html',
	styleUrls: ['./risk-profile-display.component.scss'],
})
export class RiskProfileDisplayComponent implements OnInit {
	@Input() riskProfile: RiskProfileDto;

	healthFactors: RiskOptionValueDto[];
	mentalFactors: RiskOptionValueDto[];
	workStatusFactors: RiskOptionValueDto[];
	lifeStatusFactors: RiskOptionValueDto[];
	otherFactors: RiskOptionValueDto[];

	ngOnInit() {
		const riskFactors = this.riskProfile.riskFactors;

		const sort = (factors: RiskOptionValueDto[]) => {
			return factors.sort((f1, f2) => {
				if (f1.isAtRisk > f2.isAtRisk) {
					return -1;
				} else if (f1.isAtRisk < f2.isAtRisk) {
					return 1;
				} else if (f1.option < f2.option) {
					return -1;
				} else if (f1.option > f2.option) {
					return 1;
				}

				return 0;
			});
		};

		this.healthFactors = sort(riskFactors.filter((f) => /health/gi.test(f.option)));
		this.mentalFactors = sort(riskFactors.filter((f) => /mental/gi.test(f.option)));
		this.workStatusFactors = sort(riskFactors.filter((f) => /^work/gi.test(f.option) && !/life/gi.test(f.option)));
		this.lifeStatusFactors = sort(riskFactors.filter((f) => /life/gi.test(f.option) || /quit/gi.test(f.option)));

		this.otherFactors = sort(
			riskFactors.filter(
				(f) =>
					!_.includes(this.healthFactors, f) &&
					!_.includes(this.mentalFactors, f) &&
					!_.includes(this.workStatusFactors, f) &&
					!_.includes(this.lifeStatusFactors, f)
			)
		);
	}
}
