import { Directive, HostListener, Input, OnInit } from '@angular/core';

@Directive({
	selector: '[ppBlockNonNumber]'
})
export class BlockNonNumberDirective implements OnInit {
	/* tslint:disable-next-line:no-input-rename */
	@Input('ppBlockNonNumber') enabled: boolean;

	@HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
		if (!this.enabled) {
			return;
		}
		const pastedData = event.clipboardData.getData('text');
		const num = Number(pastedData);
		if (isNaN(num)) {
			event.preventDefault();
		}
	}

	@HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
		if (!this.enabled || event.ctrlKey || event.altKey) {
			return;
		}

		const keyCode = [
			8, 9,	// Backspace, Tab
			13,		// Enter
			35, 36,	// End, Home
			37, 39,	// Left, Right
			45, 46,	// Insert, Delete
			48, 49, 50, 51, 52, 53, 54, 55, 56, 57, // 0 - 9
			96, 97, 98, 99, 100, 101, 102, 103, 104, 105, // Numeric 0 - 9
			110		// Decimal point
		];
		if (keyCode.indexOf(event.which) === -1) {
			event.preventDefault();
		}
	}

	ngOnInit() {
		if (this.enabled === undefined) {
			this.enabled = true;
		}
	}
}
