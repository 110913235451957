import { MsalConfig } from '@azure/msal-angular';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

// This is the config required by MSAL for static configuration. We provide dummy values.
// See the msalDynamicConfig object below which is updated by reading app-config.json from the server.
export const appMsalConfig: MsalConfig = {
	clientID: '',
	consentScopes: [''],
	authority: '',
	validateAuthority: false,
	storeAuthStateInCookie: isIE,
	redirectUri: ''
};

// Dynamic config object updated by loading app-config.json from the server in the Customisation service.
export const msalDynamicConfig = {
	signInAuthority: '',
	signInAuthorityCorp: '',
	passwordResetAuthority: '',
	clientId: '',
	redirectUri: ''
};
