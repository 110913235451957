import { Component, OnInit } from '@angular/core';
import { StatisticEditorBase } from '../statistic-editor-base';

@Component({
	selector: 'pp-editor-hours-minutes',
	templateUrl: './editor-hours-minutes.component.html',
	styleUrls: ['./editor-hours-minutes.component.scss']
})
export class EditorHoursMinutesComponent extends StatisticEditorBase implements OnInit {
	private _value: string;

	textMask: any;

	get value() {
		return this._value;
	}

	set value(value) {
		this._value = value;

		let h = null,
			m = null;

		let parts = value.split(':');
		if (parts.length) {
			let hours = parts[0];
			h = parseInt(hours, 10);
			if (parts.length > 1) {
				m = parseInt(parts[1], 10);
			}
		}

		h = isNaN(h) ? null : h;
		m = isNaN(m) ? null : m;

		if (this.statistic.hours !== h || this.statistic.minutes !== m) {
			this.statistic.hours = h;
			this.statistic.minutes = m;

			this.emitChange();
		}
	}

	constructor() {
		super();

		const maxHoursDigits = 4;
		this.textMask = {
			mask: rawValue => {
				let parts = rawValue.split(':');
				let intLen = 1;
				if (parts.length) {
					let hours = parts[0];
					if (hours.length) {
						intLen = hours.length;
					}
				}

				if (intLen > maxHoursDigits) {
					intLen = maxHoursDigits;
				}

				let maskArray = [];
				for (let i = 0; i < intLen; i++) {
					maskArray.push(/\d/);
				}

				if (intLen === maxHoursDigits || rawValue.endsWith(':') || parts.length > 1) {
					maskArray.push(':');
					maskArray.push(/[0-5]/);
					maskArray.push(/\d/);
				}

				return maskArray;
			},
			guide: false,
			placeholderChar: '_',
			showMask: false
		};
	}

	ngOnInit() {
		let val =
			this.statistic.hours !== undefined
				? this.statistic.hours + ':' + (this.statistic.minutes || 0).toFixed(2)
				: '';
		this.value = val;
	}
}
