
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import * as _ from 'lodash';

import { LoggerService } from './logger.service';
import {
	UserConfigDto,
	SystemPreferencesDto,
	ProfessionalDto,
} from 'app/services';

export class BookingResource {
	id: number;
	name: string;
}

export class PracticeConfig {
	practiceBookingResources: BookingResource[];
}

/**
 * Service for fetching and managing configuration for the currently authenticated user.
 * @export
 * @class ConfigService
 */
@Injectable()
export class ConfigService {
	private userConfigUrl = 'api/userConfig';
	private LOCALSTORAGEKEY_USERCONFIG = 'userConfig';
	private LOCALSTORAGEKEY_SYS_PREFS = 'sysPrefs';
	private LOCALSTORAGEKEY_PRACTICECONFIG = 'practiceConfig';
	private LOCALSTORAGEKEY_PROFESSIONALS = 'professionals';

	constructor(
		private http: HttpClient,
		private logger: LoggerService) { }

	/**
	 * Fetches the user config from the server for the currently authenticated user
	 * and stores into local storage.
	 * @returns {Observable<UserConfig>}
	 * @memberOf ConfigService
	 */
	getConfig(): Observable<UserConfigDto> {
		return this.http.get<UserConfigDto>(this.userConfigUrl).pipe(
			tap(userConfig => {
				const sysPreferences = userConfig.systemPreferences;
				userConfig.systemPreferences = null;

				if (userConfig.isPractice) {
					const practiceConfig = this.buildPracticeConfig(userConfig);
					sessionStorage.setItem(this.LOCALSTORAGEKEY_PRACTICECONFIG, JSON.stringify(practiceConfig));
				} else {
					sessionStorage.removeItem(this.LOCALSTORAGEKEY_PRACTICECONFIG);
				}

				const professionals = this.buildProfessionals(userConfig);
				sessionStorage.setItem(this.LOCALSTORAGEKEY_PROFESSIONALS, JSON.stringify(professionals));

				sessionStorage.setItem(this.LOCALSTORAGEKEY_USERCONFIG, JSON.stringify(userConfig));
				sessionStorage.setItem(this.LOCALSTORAGEKEY_SYS_PREFS, JSON.stringify(sysPreferences));
			}));
	}

	getMinVersion(): Observable<string> {
		const now = new Date();
		return this.http.get('version.json?ts=' + now.toISOString()).pipe(
			map((v: { minVersion: string}) => {
				return v.minVersion || '';
			})
		);
	}

	/**
	 * Clears the userConfig from local storage.
	 * @memberOf ConfigService
	 */
	clear() {
		sessionStorage.removeItem(this.LOCALSTORAGEKEY_USERCONFIG);
		sessionStorage.removeItem(this.LOCALSTORAGEKEY_SYS_PREFS);
		sessionStorage.removeItem(this.LOCALSTORAGEKEY_PRACTICECONFIG);
		sessionStorage.removeItem(this.LOCALSTORAGEKEY_PROFESSIONALS);
	}

	/**
	 * Gets the current UserConfig object.
	 * @readonly
	 * @type {UserConfigDto}
	 * @memberOf ConfigService
	 */
	get config(): UserConfigDto {
		return JSON.parse(sessionStorage.getItem(this.LOCALSTORAGEKEY_USERCONFIG)) || {};
	}

	/**
	 * Gets the current PracticeConfig object.
	 * @readonly
	 * @type {PracticeConfig}
	 * @memberOf ConfigService
	 */
	get practiceConfig(): PracticeConfig {
		return JSON.parse(sessionStorage.getItem(this.LOCALSTORAGEKEY_PRACTICECONFIG)) || {};
	}

	/**
	 * Gets the list of available professionals
	 * @readonly
	 * @type {ProfessionalDto[]}
	 * @memberOf ConfigService
	 */
	get professionals(): ProfessionalDto[] {
		return JSON.parse(sessionStorage.getItem(this.LOCALSTORAGEKEY_PROFESSIONALS)) || [];
	}

	/**
	 * Gets the SystemPreferences object.
	 * @readonly
	 * @type {SystemPreferncesDto}
	 * @memberOf ConfigService
	 */
	get systemPreferences(): SystemPreferencesDto {
		return JSON.parse(sessionStorage.getItem(this.LOCALSTORAGEKEY_SYS_PREFS)) || {};
	}

	get professionalId(): number {
		const config = this.config;
		return config ? config.nonPracticeProfessionalId : -1;
	}

	private buildPracticeConfig(userConfig: UserConfigDto): PracticeConfig {
		const resources = _.uniqBy(userConfig.bookingDiaryBookingResources, bdbr => bdbr.bookingResourceId);

		const config: PracticeConfig = {
			practiceBookingResources: resources.map(r => <BookingResource> {
				id: r.bookingResourceId,
				name: r.bookingResourceName
			})
		};
		return config;
	}

	private buildProfessionals(userConfig: UserConfigDto): ProfessionalDto[] {
		if (userConfig.isPractice) {
			return userConfig.practiceProfessionals;
		} else {
			return [<ProfessionalDto> {
				id: userConfig.nonPracticeProfessionalId,
				name: userConfig.name
			}];
		}
	}
}
