import { StatisticHeaderEditDto } from './../../services/server-data';
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { StatisticContainerDto } from 'app/services';

@Component({
	selector: 'pp-statistic-container',
	templateUrl: './statistic-container.component.html',
	styleUrls: ['./statistic-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticContainerComponent {
	@Input() container: StatisticContainerDto;
	@Input() header: StatisticHeaderEditDto;
	@Input() isCurrent: boolean;
	@Output() changed = new EventEmitter<StatisticContainerDto>();

	valueUpdated(container: StatisticContainerDto) {
		this.changed.emit(container);
	}
}
