import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'zeroToText'
})
export class ZeroToTextPipePipe implements PipeTransform {
	transform(value: number, args?: any): string {
		const zeroText: string = (args as string) || 'No';
		if (!value) {
			return zeroText;
		}

		return '' + value;
	}
}
