import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExtendedDetails } from '../extended-details';

@Component({
  selector: 'pp-extended-details-items-display',
  templateUrl: './extended-details-items-display.component.html',
  styleUrls: ['./extended-details-items-display.component.scss']
})
export class ExtendedDetailsItemsDisplayComponent implements OnInit {

  @Output() changed = new EventEmitter<ExtendedDetails>();
  @Input() extendedDetails: ExtendedDetails = new ExtendedDetails (); 
  @Input() redFlagColour:string;

   constructor(  ) { 
   }

   public updateColour(colour:string)
   {
      this.redFlagColour = colour;
   }

   private toggleItemState(item)
   {
      item.value = !item.value;

      this.reverseOppositeIfTrue(item,1,2); //public
      this.reverseOppositeIfTrue(item,2,1); //private

      this.changed.emit(this.extendedDetails);

   }

   private reverseOppositeIfTrue(item,index,oppositeIndex)
   {
      if (item.value && (item === this.extendedDetails.extendedDetailsItems[index]))  
      {
        this.extendedDetails.extendedDetailsItems[oppositeIndex].value = !this.extendedDetails.extendedDetailsItems[index].value;
      }
   }

  ngOnInit() {
  }

}

