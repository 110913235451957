import { Component, Input } from '@angular/core';
import { OptionValueDto } from 'app/services/server-data';

@Component({
	selector: 'pp-other-goal-display',
	templateUrl: './other-goal-display.component.html',
	styleUrls: ['./other-goal-display.component.scss']
})
export class OtherGoalDisplayComponent {
	@Input() goal: OptionValueDto;
}
