
import { of, Observable, BehaviorSubject } from 'rxjs';

import { finalize, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

import {
	GlobalSearchResultDto,
	GlobalSearchResultItemDto,
	GlobalInvoiceSearchParams,
	CommonService,
	AuthenticationService,
} from 'app/services';

import {
	BaseService
} from 'app/services/base-service';

@Injectable()
export class InvoicesSearchService extends BaseService {
	public searchText: string = null;
	public dateFrom?: Date = null;
	public dateTo?: Date = null;
	public activeOnly = false;
	public showAll = false;

	public searching = new BehaviorSubject(false);
	public canShowMore = new BehaviorSubject(false);

	public lastResult: GlobalSearchResultDto;

	constructor(
		common: CommonService,
		private authenticationService: AuthenticationService,
	) {
		super(common);

		this.authenticationService.isAuthenticated$.subscribe(isAuth => {
			if (!isAuth) {
				this.clearSearch();
			}
		});

		this.defaultDates();
	}

	search(
		searchText: string,
		all?: boolean,
		dateFrom?: Date,
		dateTo?: Date,
		activeOnly?: boolean,
		saveResults: boolean = false): Observable<GlobalSearchResultItemDto[]> {
		const params = <GlobalInvoiceSearchParams>{
			searchText,
			showAll: !!all,
			dateFrom: dateFrom ? dateFrom : null,
			dateTo: dateTo ? dateTo : null,
			activeOnly: !!activeOnly
		};

		// Don't search with no parameters.
		if (!searchText && !all && !dateFrom && !dateTo && !activeOnly) {
			this.lastResult = undefined;
			return of([]);
		}

		// Save the settings for the filter on this service.
		this.searchText = params.searchText;
		this.dateFrom = params.dateFrom;
		this.dateTo = params.dateTo;
		this.activeOnly = params.activeOnly;
		this.showAll = params.showAll;

		this.setSearching(true);

		return this.crud.loadWithPost<GlobalSearchResultDto>('api/globalSearch/invoices', params).pipe(
			tap(r => {
				if (saveResults) {
					this.lastResult = r;
				}
			}),
			map(r => r.items),
			finalize(() => this.setSearching(false)));
	}

	setSearching(state: boolean) {
		this.searching.next(state);
	}

	private clearSearch() {
		this.searchText = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;
		this.activeOnly = false;
		this.showAll = false;

		this.lastResult = undefined;

		this.defaultDates();
	}

	private defaultDates() {
		// Default in dates
		let defaultStart = moment().startOf('month').add(-1, 'months');
		let defaultEnd = defaultStart.clone().add(1, 'month').endOf('month');

		this.dateFrom = defaultStart.toDate();
		this.dateTo = defaultEnd.toDate();
	}
}
