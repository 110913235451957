import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

let identifier = 0;

@Component({
	selector: 'pp-button',
	template: `
	<button
		mat-raised-button
		[id]="identifier"
		[type]="type"
		[disabled]="disabled"
		[color]="color"
		(click)="handleClick($event)">
		{{ label }}
	</button>
`,
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
	@Input() label: string;
	@Input() type: string;
	@Input() disabled: boolean;
	@Input() color: string;
	@Output() clicked = new EventEmitter<Event>();

	public identifier = `button-${identifier++}`;

	ngOnInit() {
		if (!this.type) {
			this.type = 'button';
		}
	}

	handleClick(ev: Event) {
		ev.stopPropagation();
		if (this.disabled) {
			return;
		}
		this.clicked.emit(ev);
	}
}
