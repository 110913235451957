import { EapServicesClientSurveyDto } from 'app/services';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'pp-eap-services-client-questionnaire-display',
	templateUrl: './eap-services-client-questionnaire-display.component.html',
	styleUrls: ['./eap-services-client-questionnaire-display.component.scss']
})
export class EapServicesClientQuestionnaireDisplayComponent {
	@Input() questionnaire: EapServicesClientSurveyDto;
}
