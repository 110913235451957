import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatButton, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
	selector: 'pp-warnings-dialog',
	templateUrl: './warnings-dialog.component.html',
	styleUrls: ['./warnings-dialog.component.scss'],
})
export class WarningsDialogComponent implements OnInit, AfterViewInit {
	@ViewChild('cancelButton', { static: false }) cancelButton: MatButton;

	// Template properties
	title: string;
	messages: string[];
	withConfirm: boolean;
	confirmCaption: string;
	cancelCaption = 'Cancel';

	constructor(
		public dialogRef: MatDialogRef<WarningsDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: { title: string; messages: string[]; withConfirm?: boolean; asYesNo?: boolean }
	) {}

	ngOnInit() {
		this.title = this.data.title;
		this.messages = this.data.messages;
		this.withConfirm = this.data.withConfirm;

		if (this.data.asYesNo) {
			this.confirmCaption = 'Yes';
			this.cancelCaption = 'No';
		} else {
			this.confirmCaption = this.messages.length > 1 ? `Confirm ${this.messages.length} warnings` : 'Confirm';
		}
	}

	ngAfterViewInit() {
		if (this.cancelButton) {
			this.cancelButton.focus();
		}
	}

	close() {
		this.dialogRef.close();
	}

	confirm() {
		this.dialogRef.close(true);
	}

	cancel() {
		this.close();
	}
}
