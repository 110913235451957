import { Inject, Injectable, InjectionToken, isDevMode } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
	constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (isDevMode()) {
			// 5 minute timeout in development - to allow for debugging.
			this.defaultTimeout = 5 * 60 * 1000;
		}

		const timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
		const timeoutValueNumeric = Number(timeoutValue);

		return next.handle(req).pipe(
			timeout(timeoutValueNumeric),
			catchError((err) => {
				return throwError(err);
			})
		);
	}
}
