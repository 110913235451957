import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { CrudService } from './crud.service';
import { AppointmentsDto, OpenActivityHeadersCountDto, OpenInvoicesCountDto } from 'app/services/server-data';

@Injectable()
export class DashboardService {
	constructor(private crud: CrudService) {}

	getDashboardAppointments(numAppointmentDays: number) {
		const startDate = this.getDashboardAppointmentsStartDate();
		return this.crud.loadWithGet<AppointmentsDto>('api/appointments/dashboard', {
			startDate: startDate.toISOString(),
			numDays: numAppointmentDays.toString()
		});
	}

	getDashboardAppointmentsStartDate(): moment.Moment {
		const startDate = moment().startOf('day');
		return startDate;
	}

	getDashboardAppointmentsEndDate(numAppointmentDays: number): moment.Moment {
		const startDate = this.getDashboardAppointmentsStartDate();
		const endDate = startDate.clone();
		endDate.add(numAppointmentDays - 1, 'day').startOf('day');
		return endDate;
	}

	getDashboardOpenActivityHeadersCount() {
		return this.crud.loadWithGet<OpenActivityHeadersCountDto>('api/openActivityHeaders/count');
	}

	getDashboardOpenInvoicesCount() {
		return this.crud.loadWithGet<OpenInvoicesCountDto>('api/openInvoices/count');
	}
}
