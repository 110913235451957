import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import {
	IClientEditLoadDto,
	IOrganisationPartEditDto,
	OrganisationDto,
	OrganisationGroupDto,
	OrganisationDivisionDto,
	FormUtils,
	UpdaterOptions,
	OrganisationDepartmentDto
} from 'app/services';

import { IForm } from 'app/interfaces';

@Component({
	selector: 'pp-organisation-hierarchy-edit',
	templateUrl: './organisation-hierarchy-edit.component.html',
	styleUrls: ['./organisation-hierarchy-edit.component.scss']
})
export class OrganisationHierarchyEditComponent implements OnChanges, IForm {
	private valueChangeSubscriber: Subscription;

	@Input() organisation: OrganisationDto;
	@Input() loadDto: IClientEditLoadDto;
	@Input() editDto: IOrganisationPartEditDto;
	@Input() immediateUpdate: boolean;
	@Input() readOnly: boolean;
	@Output() changed = new EventEmitter<IOrganisationPartEditDto>();

	form: FormGroup;
	group: OrganisationGroupDto;
	division: OrganisationDivisionDto;

	groups: OrganisationGroupDto[];
	divisions: OrganisationDivisionDto[];
	departments: OrganisationDepartmentDto[];

	constructor(private fb: FormBuilder) {
		this.buildForm();
	}

	ngOnChanges(changes) {
		if (changes.loadDto && this.loadDto) {
			this.organisation = this.loadDto.organisation;
		}

		if (this.organisation && this.editDto) {

			this.form.patchValue(this.editDto);

			this.groups = this.getGroups(this.editDto.organisationGroupId);

			// Watch for changes to group to update divisions
			this.form.controls.organisationGroupId.valueChanges.subscribe((value: number) => {
				this.findGroup(value);
			});

			// Watch for changes to division to update departments
			this.form.controls.organisationDivisionId.valueChanges.subscribe((value: number) => {
				this.findDivision(value);
			});

			this.findGroup(this.editDto.organisationGroupId);

			if (this.valueChangeSubscriber) {
				this.valueChangeSubscriber.unsubscribe();
			}
			this.valueChangeSubscriber = this.form.valueChanges.subscribe(v => this.updated(v));
		}

		if (changes.readOnly) {
			if (this.readOnly) {
				this.form.disable();
			} else {
				this.form.enable();
			}
		}

		this.enableDisable();
	}

	getGroups(groupId?: number): OrganisationGroupDto[] {
		if (!this.organisation) {
			return [];
		}
		const groups = this.organisation.organisationGroups.filter(g => !g.isInactive || g.id === groupId);
		return groups;
	}

	getDivisions(divisionId?: number): OrganisationDivisionDto[] {
		if (!this.group) {
			return [];
		}
		const divisions = this.group.organisationDivisions.filter(d => !d.isInactive || d.id === divisionId);
		return divisions;
	}

	getDepartments(departmentId?: number): OrganisationDepartmentDto[] {
		if (!this.division) {
			return [];
		}
		const departments = this.division.organisationDepartments.filter(d => !d.isInactive || d.id === departmentId);
		return departments;
	}

	updated(value: IOrganisationPartEditDto) {
		if (!this.immediateUpdate) {
			return;
		}

		this.applyChanges(this.editDto);
		this.changed.emit(this.editDto);
	}

	getForm(): FormGroup {
		return this.form;
	}

	applyChanges(obj) {
		console.log('applyChanges(obj)',obj)
		FormUtils.applyChanges(this.form, obj, UpdaterOptions.NULL_IF_MISSING);
	}

	private findGroup(id: number) {
		this.group = this.organisation && this.organisation.organisationGroups.find(g => g.id === id);
		if (
			!this.group ||
			!this.group.organisationDivisions.length ||
			!this.group.organisationDivisions.find(d => d.id === this.editDto.organisationDivisionId)
		) {
			this.form.patchValue({
				organisationDivisionId: undefined
			});
			this.editDto.organisationDivisionId = undefined;
		}
		this.divisions = this.getDivisions(this.editDto.organisationDivisionId);
		this.findDivision(this.editDto.organisationDivisionId);
	}

	private findDivision(id: number) {
		this.division = this.group && this.group.organisationDivisions.find(d => d.id === id);
		if (
			!this.division ||
			!this.division.organisationDepartments.length ||
			!this.division.organisationDepartments.find(d => d.id === this.editDto.organisationDepartmentId)
		) {
			this.form.patchValue({
				organisationDepartmentId: undefined
			});
			this.editDto.organisationDepartmentId = undefined;
		}
		this.departments = this.getDepartments(this.editDto.organisationDepartmentId);
		this.enableDisable();
	}

	private enableDisable() {
		if (this.readOnly) {
			return;
		}

		// Get the controls for the hierarchy.
		const groupControl = this.form.controls.organisationGroupId,
			divisionControl = this.form.controls.organisationDivisionId,
			departmentControl = this.form.controls.organisationDepartmentId;

		// Disable any controls that don't have any selectable entries.
		this.organisation &&
		this.organisation.organisationGroups &&
		this.organisation.organisationGroups.length &&
		this.organisation.organisationGroups.filter(g => !g.isInactive).length
			? groupControl.enable({ onlySelf: true, emitEvent: false })
			: groupControl.disable({ onlySelf: true, emitEvent: false });

		this.group &&
		this.group.organisationDivisions &&
		this.group.organisationDivisions.length &&
		this.group.organisationDivisions.filter(d => !d.isInactive).length
			? divisionControl.enable({ onlySelf: true, emitEvent: false })
			: divisionControl.disable({ onlySelf: true, emitEvent: false });

		this.division &&
		this.division.organisationDepartments &&
		this.division.organisationDepartments.length &&
		this.division.organisationDepartments.filter(d => !d.isInactive).length
			? departmentControl.enable({ onlySelf: true, emitEvent: false })
			: departmentControl.disable({ onlySelf: true, emitEvent: false });
	}

	private buildForm() {
		this.form = this.fb.group({
			organisationGroupId: ['', Validators.required],
			organisationDivisionId: ['', Validators.required],
			organisationDepartmentId: ['', Validators.required]
		});
	}
}
