import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import {
	ContractInfoDto
} from 'app/services';

@Component({
	selector: 'pp-contract-notes-display',
	templateUrl: './contract-notes-display.component.html',
	styleUrls: ['./contract-notes-display.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractNotesDisplayComponent implements OnInit {
	hasServiceType: boolean;
	hasContract: boolean;
	hasCustomer: boolean;

	// Template properties
	serviceTypeAlertHtml: SafeHtml;
	contractAlertHtml: SafeHtml;
	customerAlertHtml: SafeHtml;

	@Input() contractInfo: ContractInfoDto;

	constructor(
		private domSanitizer: DomSanitizer,
	) {}

	ngOnInit() {
		this.serviceTypeAlertHtml = this.domSanitizer.bypassSecurityTrustHtml(this.contractInfo.alertServiceType);
		this.contractAlertHtml = this.domSanitizer.bypassSecurityTrustHtml(this.contractInfo.alertContract);
		this.customerAlertHtml = this.domSanitizer.bypassSecurityTrustHtml(this.contractInfo.alertCustomer);

		this.hasServiceType = !this.isEmptyHtml(this.contractInfo.alertServiceType);
		this.hasContract = !this.isEmptyHtml(this.contractInfo.alertContract);
		this.hasCustomer = !this.isEmptyHtml(this.contractInfo.alertCustomer);
	}

	scrollIntoView(anchorId) {
		setTimeout(() => {
			const anchor = document.getElementById(anchorId);
			if (anchor) {
				anchor.focus();
				anchor.scrollIntoView();
			}
		});
	}

	private isEmptyHtml(html: string): boolean {
		if (!html) {
			return true;
		}
		return html.toLocaleLowerCase() === '<p></p>';
	}
}
