import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
	transform(value: any, args?: any): any {
		const bytes = parseInt(value, 10);

		const gb = Math.pow(2, 30);
		const mb = Math.pow(2, 20);
		const kb = Math.pow(2, 10);

		if (bytes >= gb) {
			let size = bytes / gb;
			return `${size.toFixed(2)} GB`;
		}
		if (bytes >= mb) {
			let size = bytes / mb;
			return `${size.toFixed(2)} MB`;
		}
		if (bytes >= kb) {
			let size = bytes / kb;
			return `${size.toFixed(2)} KB`;
		}
		if (bytes >= 0) {
			return `${bytes} B`;
		}

		return '0 B';
	}
}
