import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { ExtendedDetails } from '../extended-details';

@Component({
  selector: 'pp-extended-details-edit-other',
  templateUrl: './extended-details-edit-other.component.html',
  styleUrls: ['./extended-details-edit-other.component.scss']
})
export class ExtendedDetailsEditOtherComponent implements OnInit {
  @Input() extendedDetails: ExtendedDetails= new ExtendedDetails();
  @Output() changed = new EventEmitter<ExtendedDetails>();

  private today = new Date();

  constructor() {
   }

  ngOnInit() {
  }
  
  private changeColour(colour:string)
  {
    this.extendedDetails.redFlagColour = colour;    
    this.changed.emit(this.extendedDetails);

  }

  public changeHasUsedServiceBefore(hasUsedServiceBefore:boolean)
  {
    this.extendedDetails.hasUsedServiceBefore = hasUsedServiceBefore;
  } 

  openDatePicker(datePicker) {
    datePicker.open();
  }

  closeDatePicker(eventDate: Date, datePicker?:any) {
    // get month and year from eventDate and close datepicker, thus not allowing user to select date

    this.extendedDetails.usedServiceMonthFormatted = moment(eventDate).format('MMMM YYYY');
    this.extendedDetails.usedServiceMonth = eventDate;
    this.changed.emit(this.extendedDetails);  

    datePicker.close();    
  }

  detailsChanged()
  {
    this.changed.emit(this.extendedDetails); 
  }

}