import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
	MatDialogRef,
	MAT_DIALOG_DATA
} from '@angular/material';
import {
	CommunicationAddressDto,
	ConfigService,
	CountryLookupDto,
	FormUtils,
} from 'app/services';

@Component({
	selector: 'pp-comm-addr-edit-modal',
	templateUrl: './comm-addr-edit-modal.component.html',
	styleUrls: ['./comm-addr-edit-modal.component.scss']
})
export class CommAddrEditModalComponent implements OnInit {
	form: FormGroup;
	address: CommunicationAddressDto;
	countries: CountryLookupDto[];

	constructor(
		public dialogRef: MatDialogRef<CommAddrEditModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private config: ConfigService
	) {	}

	ngOnInit() {
		this.buildForm();

		this.address = this.data.address;
		this.countries = this.data.countries;

		this.form.patchValue(this.address);

		if (!this.address.countryId && this.address.countryVisible) {
			this.form.patchValue({
				countryId: 		this.config.systemPreferences.defaultCountryId
			})
		}
	}

	getCountry() {
		// Note: 'this' isn't the CommAddressEditComponent object.
		// This function is being passed directly to the pp-select component
		// for it to call. The 'this' object will be the Country data object.
		const callingCode = this['callingCode'],
			abbrev = this['iso31661Alpha2Code'];

		return `${callingCode} (${abbrev})`;
	}

	submit() {
		FormUtils.applyChanges(this.form, this.address);
	}

	private buildForm() {
		this.form = this.fb.group({
			areaCode: [''],
			text: [''],
			countryId: ['', Validators.required],
			isForNotifyEmail: [''],
			isForNotifySms: [''],
			isCanSendEmail: [''],
			isCanLeaveMessage: [''],
			isPrimary: [''],
			isSecondary: ['']
		});
	}
}
