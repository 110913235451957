import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'app/shared/shared.module';

import { AddChoiceModalComponent } from './statistics-editors/container-two-num/add-choice-modal/add-choice-modal.component';
import { AdditionalSessionEditComponent } from './additional-session-edit/additional-session-edit.component';
import { AdditionalSessionStatus } from './additional-session-status.service';
import { BooleanGoalDisplayComponent } from './statistics-editors/custom/boolean-goal-display/boolean-goal-display.component';
import { CalendarCommonService } from './calendar-common/calendar-common.service';
import { ChipsPickerComponent } from './statistics-editors/container-pick-list-grid/chips-picker/chips-picker.component';
import { ClientDetailsEditComponent } from './client-details-edit/client-details-edit.component';
import { ClientEditComponent } from './statistic-headers/client-edit/client-edit.component';
import { ClientEditDetailsComponent } from './statistic-headers/client-edit/client-edit-details/client-edit-details.component';
import { ClientPartnerEditComponent } from './client-partner-edit/client-partner-edit.component';
import { ClientEditSessionsComponent } from './statistic-headers/client-edit/client-edit-sessions/client-edit-sessions.component';
import { ClientEditStatisticsComponent } from './statistic-headers/client-edit/client-edit-statistics/client-edit-statistics.component';
import { ClientSessionEditComponent } from './statistic-headers/client-edit/client-edit-sessions/client-session-edit/client-session-edit.component';
import { ClientsComponent } from './statistic-headers/clients/clients.component';
import { ClinicalProfileDisplayComponent } from './statistics-editors/custom/clinical-profile-display/clinical-profile-display.component';
import { CommAddrDisplayEditComponent } from './comm-address-edit/comm-addr-display-edit/comm-addr-display-edit.component';
import { CommAddrEditModalComponent } from './comm-address-edit/comm-addr-edit-modal/comm-addr-edit-modal.component';
import { CommAddrEditorComponent } from './comm-address-edit/comm-addr-editor/comm-addr-editor.component';
import { CompletionPercentComponent } from './statistics-editors/custom/completion-percent/completion-percent.component';
import { ContainerControlsComponent } from './statistics-editors/container-controls/container-controls.component';
import { ContainerIndividualGridComponent } from './statistics-editors/container-individual-grid/container-individual-grid.component';
import { ContainerPickAndAddTwoNumComponent } from './statistics-editors/container-pick-and-add-two-num/container-pick-and-add-two-num.component';
import { ContainerPickAndAddTwoNumItemComponent } from './statistics-editors/container-pick-and-add-two-num/container-pick-and-add-two-num-item/container-pick-and-add-two-num.-itemcomponent';
import { ContainerPickListGridComponent } from './statistics-editors/container-pick-list-grid/container-pick-list-grid.component';
import { ContainerTwoNumComponent } from './statistics-editors/container-two-num/container-two-num.component';
import { ContainerTwoNumPlusMinusEditComponent } from './statistics-editors/container-two-num/container-two-num-plus-minus-edit/container-two-num-plus-minus-edit.component';
import { ContractNotesDisplayComponent } from './contract-terms/contract-notes-display/contract-notes-display.component';
import { ContractServiceTypeItemsDisplayComponent } from './contract-terms/contract-service-type-items-display/contract-service-type-items-display.component';
import { ContractTermsComponent } from './contract-terms/contract-terms.component';
import { ContractTermsService } from './contract-terms/contract-terms.service';
import { EapServicesClientQuestionnaireComponent } from './statistics-editors/custom/eap-services-client-questionnaire/eap-services-client-questionnaire.component';
import { EapServicesClientQuestionnaireDisplayComponent } from './statistics-editors/custom/eap-services-client-questionnaire-display/eap-services-client-questionnaire-display.component';
import { EditorHoursMinutesComponent } from './statistics-editors/editor-hours-minutes/editor-hours-minutes.component';
import { EditorNumericTextboxDecComponent } from './statistics-editors/editor-numeric-textbox-dec/editor-numeric-textbox-dec.component';
import { EditorNumericTextboxIntComponent } from './statistics-editors/editor-numeric-textbox-int/editor-numeric-textbox-int.component';
import { EditorPicklistComponent } from './statistics-editors/editor-picklist/editor-picklist.component';
import { EditorTextboxComponent } from './statistics-editors/editor-textbox/editor-textbox.component';
import { EditorTextboxMultiComponent } from './statistics-editors/editor-textbox-multi/editor-textbox-multi.component';
import { EditorTwoNumComponent } from './statistics-editors/editor-two-num/editor-two-num.component';
import { ExtendedDetailsEditComponent } from './extended-details/extended-details-edit/extended-details-edit.component';
import { ExtendedDetailsItemsDisplayComponent } from './extended-details/extended-details-items-display/extended-details-items-display.component';
import { ExtendedDetailsEditOtherComponent } from './extended-details/extended-details-edit-other/extended-details-edit-other.component';
import { PersonalDetailsEditComponent } from './personal-details-edit/personal-details-edit.component';
import { HeaderEditComponent } from './statistic-headers/header-edit/header-edit.component';
import { HeaderEditStatisticsComponent } from './statistic-headers/header-edit/header-edit-statistics/header-edit-statistics.component';
import { HeaderEditSummaryComponent } from './statistic-headers/header-edit/header-edit-summary/header-edit-summary.component';
import { HeadersComponent } from './statistic-headers/headers/headers.component';
import { OrganisationHierarchyEditComponent } from './organisation-hierarchy-edit/organisation-hierarchy-edit.component';
import { OtherGoalDisplayComponent } from './statistics-editors/custom/other-goal-display/other-goal-display.component';
import { RiskProfileDisplayComponent } from './statistics-editors/custom/risk-profile-display/risk-profile-display.component';
import { RiskProfileFactorComponent } from './statistics-editors/custom/risk-profile-factor/risk-profile-factor.component';
import { StatisticContainerComponent } from './statistic-container/statistic-container.component';
import { StatisticHeadersComponent } from './statistic-headers/statistic-headers.component';
import { StatisticsComponent } from './statistics/statistics.component';

@NgModule({
	imports: [CommonModule, SharedModule],
	declarations: [
		AddChoiceModalComponent,
		AdditionalSessionEditComponent,
		BooleanGoalDisplayComponent,
		ChipsPickerComponent,
		ClientDetailsEditComponent,
		ClientEditComponent,
		ClientEditDetailsComponent,
		ClientPartnerEditComponent,
		ClientEditSessionsComponent,
		ClientEditStatisticsComponent,
		ClientSessionEditComponent,
		ClientsComponent,
		ClinicalProfileDisplayComponent,
		CommAddrDisplayEditComponent,
		CommAddrEditModalComponent,
		CommAddrEditorComponent,
		CompletionPercentComponent,
		ContainerControlsComponent,
		ContainerIndividualGridComponent,
		ContainerPickAndAddTwoNumComponent,
		ContainerPickAndAddTwoNumItemComponent,
		ContainerPickListGridComponent,
		ContainerTwoNumComponent,
		ContainerTwoNumPlusMinusEditComponent,
		ContractNotesDisplayComponent,
		ContractServiceTypeItemsDisplayComponent,
		ContractTermsComponent,
		EapServicesClientQuestionnaireComponent,
		EapServicesClientQuestionnaireDisplayComponent,
		EditorHoursMinutesComponent,
		EditorNumericTextboxDecComponent,
		EditorNumericTextboxIntComponent,
		EditorPicklistComponent,
		EditorTextboxComponent,
		EditorTextboxMultiComponent,
		EditorTwoNumComponent,
		ExtendedDetailsEditComponent,
		ExtendedDetailsItemsDisplayComponent,
		ExtendedDetailsEditOtherComponent,
		PersonalDetailsEditComponent,
		HeaderEditComponent,
		HeaderEditStatisticsComponent,
		HeaderEditSummaryComponent,
		HeadersComponent,
		OrganisationHierarchyEditComponent,
		OtherGoalDisplayComponent,
		RiskProfileDisplayComponent,
		RiskProfileFactorComponent,
		StatisticContainerComponent,
		StatisticHeadersComponent,
		StatisticsComponent,
	],
	exports: [
		AddChoiceModalComponent,
		AdditionalSessionEditComponent,
		ChipsPickerComponent,
		ClientDetailsEditComponent,
		ClientEditComponent,
		ClientEditDetailsComponent,
		ClientPartnerEditComponent,
		ClientEditSessionsComponent,
		ClientEditStatisticsComponent,
		ClientSessionEditComponent,
		ClientsComponent,
		CommAddrDisplayEditComponent,
		CommAddrEditModalComponent,
		CommAddrEditorComponent,
		ContainerControlsComponent,
		ContainerIndividualGridComponent,
		ContainerPickAndAddTwoNumComponent,
		ContainerPickAndAddTwoNumItemComponent,
		ContainerPickListGridComponent,
		ContainerTwoNumComponent,
		ContainerTwoNumPlusMinusEditComponent,
		ContractTermsComponent,
		EapServicesClientQuestionnaireComponent,
		EditorHoursMinutesComponent,
		EditorNumericTextboxDecComponent,
		EditorNumericTextboxIntComponent,
		EditorPicklistComponent,
		EditorTextboxComponent,
		EditorTextboxMultiComponent,
		EditorTwoNumComponent,
		ExtendedDetailsEditComponent,
		ExtendedDetailsItemsDisplayComponent,
		ExtendedDetailsEditOtherComponent,
		PersonalDetailsEditComponent,
		HeaderEditComponent,
		HeaderEditStatisticsComponent,
		HeaderEditSummaryComponent,
		HeadersComponent,
		OrganisationHierarchyEditComponent,
		StatisticContainerComponent,
		StatisticHeadersComponent,
		StatisticsComponent,
	],
	providers: [ContractTermsService, CalendarCommonService, AdditionalSessionStatus],
	entryComponents: [CommAddrEditModalComponent, AddChoiceModalComponent],
})
export class CommonEditingModule {}
