import { Component, OnInit, Input } from '@angular/core';
import { ClinicalProfileDto, OptionValueDto } from 'app/services/server-data';

@Component({
	selector: 'pp-clinical-profile-display',
	templateUrl: './clinical-profile-display.component.html',
	styleUrls: ['./clinical-profile-display.component.scss']
})
export class ClinicalProfileDisplayComponent implements OnInit {
	@Input() clinicalProfile: ClinicalProfileDto;

	concerns: OptionValueDto[];
	booleanGoals: OptionValueDto[];
	otherGoals: OptionValueDto[];

	hasOtherGoals: boolean;

	ngOnInit() {
		this.concerns = this.clinicalProfile.concerns || [];
		this.booleanGoals = (<OptionValueDto[]>(
			(this.clinicalProfile.goals || []).filter((g: any) => g.value === 'True' || g.value === 'False')
		)).sort((g1, g2) => {
			let g1Val = g1.value === 'True',
				g2Val = g2.value === 'True';

			return g1Val === g2Val ? 0 : !!g2Val && !g1Val ? 1 : -1;
		});

		this.otherGoals = <OptionValueDto[]>(
			(this.clinicalProfile.goals || []).filter((g: any) => g.value !== 'True' && g.value !== 'False')
		);

		this.hasOtherGoals = !!this.otherGoals.length;
	}
}
