import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { LoggerService } from './logger.service';
import { SaveResultDto, DeleteResultDto } from 'app/services/server-data';
import { JsonService } from './json.service';

@Injectable()
export class CrudService {
	constructor(
		private http: HttpClient,
		private logger: LoggerService,
		private json: JsonService,
	) { }

	save<T>(saveUrl: string, dto: T): Observable<SaveResultDto<T>>	{
		const body = this.json.stringify(dto);
		return this.http.post<SaveResultDto<T>>(saveUrl, body);
	}

	saveWithInfo<T, TResult>(saveUrl: string, dto: T): Observable<SaveResultDto<TResult>>	{
		const body = this.json.stringify(dto);
		return this.http.post<SaveResultDto<TResult>>(saveUrl, body);
	}

	delete(url: string): Observable<DeleteResultDto>	{
		return this.http.delete<DeleteResultDto>(url);
	}

	loadWithPost<T>(loadUrl: string, params: {}): Observable<T> {
		const body = this.json.stringify(params);
		return this.http.post<T>(loadUrl, body);
	}

	loadWithGet<T>(loadUrl: string, params?: {}): Observable<T> {
		let httpParams: HttpParams;
		if (params) {
			httpParams = new HttpParams();
			for (let key in params) {
				if (params.hasOwnProperty(key)) {
					const element = params[key];
					httpParams = httpParams.append(key, element);
				}
			}
		}

		return this.http.get<T>(loadUrl, {
			params: httpParams
		});
	}
}
