import { ContentTypeInterceptor } from './content-type.interceptor';
import { TimeoutInterceptor, DEFAULT_TIMEOUT } from './timeoutInterceptor';
import { CustomisationService } from './services/customisation.service';
import { QuillModule } from 'ngx-quill';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './interceptor';
import { CustomErrorHandler } from './services/error-handler';

import { routedComponents, AppRoutingModule } from './app-routing.module';

import { SharedModule } from './shared/shared.module';
import { CommonEditingModule } from './common-editing/common-editing.module';

import { AuthGuard, LogoutGuard } from './_guards';

import { MsalModule } from '@azure/msal-angular';

import {
	AgGridService,
	AuthenticationService,
	AuthsService,
	CheckSaveChangesService,
	CommonService,
	ConfigService,
	CrudService,
	DashboardService,
	FormsService,
	JsonService,
	LoadingStateService,
	LoggerService,
	LogoutService,
	ModalService,
	SearchStateService,
	SessionStorageManagementService,
	UtilsService,
	CheckSaveChangesDialogComponent,
	SaveProgressDialogComponent,
	WarningsService,
} from './services';

import { CasesSearchService } from 'app/search/case-search/cases-search.service';
import { InvoicesSearchService } from 'app/search/invoice-search/invoices-search.service';

import { SearchComponent } from './search/search.component';
import { WarningsDialogComponent } from './services/warnings-dialog/warnings-dialog.component';
import { CaseSearchComponent } from './search/case-search/case-search.component';
import { InvoiceSearchComponent } from './search/invoice-search/invoice-search.component';

import { BaseViewComponent } from './shared/base-view/base-view-component';
import { BaseEditComponent } from './shared/base-edit/base-edit.component';
import { PracticeNavigatorComponent } from './practice-navigator/practice-navigator.component';
import { initScaleProps } from 'fullcalendar-scheduler/src/timeline/TimelineView.defaults';
import { appMsalConfig } from './msal.config';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ICalDetailsDialogComponent } from './i-cal-details-dialog/i-cal-details-dialog.component';
import { HttpRetryInterceptor } from './httpRetryInterceptor';

export function initApp(svc: CustomisationService) {
	return () => {
		return svc.loadConfigurationData();
	};
}

@NgModule({
	declarations: [
		AppComponent,
		routedComponents,
		BaseEditComponent,
		BaseViewComponent,
		CaseSearchComponent,
		CheckSaveChangesDialogComponent,
		ConfirmDialogComponent,
		ICalDetailsDialogComponent,
		InvoiceSearchComponent,
		PracticeNavigatorComponent,
		SaveProgressDialogComponent,
		SearchComponent,
		WarningsDialogComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		SharedModule,

		HttpClientModule,
		JwtModule.forRoot({
			config: {
				tokenGetter: () => AuthenticationService.getToken()
			},
		}),
		QuillModule.forRoot(),

		CommonEditingModule,
		AppRoutingModule,
		MsalModule.forRoot(appMsalConfig),
	],
	providers: [
		AgGridService,
		AuthGuard,
		AuthenticationService,
		AuthsService,
		CasesSearchService,
		CheckSaveChangesService,
		CommonService,
		ConfigService,
		CrudService,
		DashboardService,
		DatePipe,
		DecimalPipe,
		FormsService,
		InvoicesSearchService,
		JsonService,
		LoadingStateService,
		LoggerService,
		LogoutGuard,
		LogoutService,
		ModalService,
		SearchStateService,
		SessionStorageManagementService,
		Title,
		UtilsService,
		WarningsService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRetryInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: Interceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ContentTypeInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TimeoutInterceptor,
			multi: true,
		},
		{ provide: DEFAULT_TIMEOUT, useValue: 30000 },
		{
			provide: ErrorHandler,
			useClass: CustomErrorHandler,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: initApp,
			multi: true,
			deps: [CustomisationService],
		},
	],
	entryComponents: [
		CheckSaveChangesDialogComponent,
		ConfirmDialogComponent,
		ICalDetailsDialogComponent,
		SaveProgressDialogComponent,
		SearchComponent,
		WarningsDialogComponent,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
