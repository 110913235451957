import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from 'app/_guards';
import { LogoutGuard } from 'app/_guards';

import { MainComponent } from './main/main.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ActiveCasesComponent } from './professional-dashboard/active-cases/active-cases.component';
import { ActiveInvoicesComponent } from './professional-dashboard/active-invoices/active-invoices.component';
import { LoginComponent } from './login/login.component';

import { ProfessionalDashboardComponent } from './professional-dashboard/professional-dashboard.component';
import { DashboardAppointmentsComponent } from './professional-dashboard/dashboard-appointments/dashboard-appointments.component';

const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
		canActivate: [LogoutGuard]
	},
	{
		path: '',
		component: MainComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				component: DashboardComponent
			},
			{
				path: '',
				redirectTo: 'dashboard',
				pathMatch: 'full'
			},
			// All the modules below are lazy-loaded.
			{
				path: 'cases',
				loadChildren: () => import('./cases/cases.module').then(m => m.CasesModule)
			},
			{
				path: 'invoices',
				loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule)
			},
			{
				path: 'appointments',
				loadChildren: () => import('./appointments/appointments.module').then(m => m.AppointmentsModule)
			},
			{
				path: 'availability',
				loadChildren: () => import('./availability/availability.module').then(m => m.AvailabilityModule)
			},
			{
				path: 'activity-query',
				loadChildren: () => import('./activity-query/activity-query.module').then(m => m.ActivityQueryModule)
			},
			{
				path: 'resource-hub',
				loadChildren: () => import('./resource-hub/resource-hub.module').then(m => m.ResourceHubModule)
			}
		]
	},
	{
		path: '*',
		redirectTo: ''
	}
];

@NgModule({
	imports: [
		// Configure the router to pre-load all lazily-loaded
		// modules.
		RouterModule.forRoot(routes, {
			//enableTracing: true, // <-- debugging purposes only
			preloadingStrategy: PreloadAllModules
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
export const routedComponents: any[] = [
	MainComponent,
	LoginComponent,
	DashboardComponent,
	ProfessionalDashboardComponent,
	ActiveCasesComponent,
	ActiveInvoicesComponent,
	DashboardAppointmentsComponent
];
