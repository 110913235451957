import { BehaviorSubject } from 'rxjs';
import { Component, OnChanges, SimpleChanges, Input } from '@angular/core';
import { EapServicesClientQuestionnaireService } from '../eap-services-client-questionnaire.service';
import { EapServicesClientSurveyDto } from 'app/services';

@Component({
	selector: 'pp-eap-services-client-questionnaire',
	templateUrl: './eap-services-client-questionnaire.component.html',
	styleUrls: ['./eap-services-client-questionnaire.component.scss']
})
export class EapServicesClientQuestionnaireComponent implements OnChanges {
	@Input() active: boolean;
	loading$: BehaviorSubject<boolean>;
	questionnaire$: BehaviorSubject<EapServicesClientSurveyDto>;

	constructor(private service: EapServicesClientQuestionnaireService) {
		this.loading$ = service.refreshing$;
		this.questionnaire$ = service.questionnaire$;
	}

	ngOnChanges(changes: SimpleChanges) {
		// Every time we're activated, let the service know to potentially refresh the survey.
		if (changes.active && this.active) {
			this.service.onActivated();
		}
	}
}
