import { Component, OnInit, Input } from '@angular/core';
import { OptionValueDto } from 'app/services';

@Component({
	selector: 'pp-boolean-goal-display',
	templateUrl: './boolean-goal-display.component.html',
	styleUrls: ['./boolean-goal-display.component.scss']
})
export class BooleanGoalDisplayComponent implements OnInit {
	@Input() goal: OptionValueDto;

	value: boolean;

	ngOnInit() {
		this.value = this.goal.value === 'True';
	}
}
