import { ICalDialogService } from './../../i-cal-details-dialog/i-cal-dialog.service';
import { tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';

import {
	DashboardService,
	AppointmentsDto,
	AppointmentDto,
	AppointmentType,
	CommonService,
	ProfessionalDto,
	CommAddressDisplayDto,
} from 'app/services';

import { BaseViewComponent } from 'app/shared/base-view/base-view-component';
import { ICalUrls } from '../../i-cal-details-dialog/iCalUrls.model';

class Day {
	name: string;
	date: Date;
	appointments: AppointmentDto[];
}

class PhoneNumber {
	number: string;
	text: string;
}

const intMaxValue = Math.pow(2, 31) - 1;
@Component({
	selector: 'pp-dashboard-appointments',
	templateUrl: './dashboard-appointments.component.html',
	styleUrls: ['./dashboard-appointments.component.scss'],
})
export class DashboardAppointmentsComponent extends BaseViewComponent implements OnInit {
	private professionalsById: _.Dictionary<ProfessionalDto>;

	appointments: AppointmentsDto;
	numAppointmentDays = 4;

	practiceMode: boolean;
	hasICalUrl: boolean;

	iCalUrls: ICalUrls;

	view: Day[];

	constructor(
		common: CommonService,
		private router: Router,
		private dashboardService: DashboardService,
		private iCalDialog: ICalDialogService
	) {
		super(common);

		const userConfig = common.config.config;

		const profs = _.keyBy(userConfig.practiceProfessionals, (p) => p.id);
		this.professionalsById = profs;
		this.practiceMode = userConfig.isPractice;

		this.hasICalUrl = !!userConfig.iCalUserUrl;
		if (this.hasICalUrl) {
			this.iCalUrls = {
				guideUrl: userConfig.iCalGuideUrl,
				professionalUrl: userConfig.iCalUserUrl,
			};
		}
	}

	ngOnInit() {
		this.refresh();
	}

	showICalDetails(_) {
		this.iCalDialog.showDetails(this.iCalUrls);
	}

	refresh() {
		this.loading = true;
		this.dashboardService
			.getDashboardAppointments(this.numAppointmentDays)
			.pipe(tap((a) => (a.appointments = a.appointments.filter((a) => !a.isReserved))))
			.subscribe(
				(result) => this.handleDashboardData(result),
				(err) => this.handleError(err, 'loading appointments')
			);
	}

	getPhoneNumbers(appointment: AppointmentDto): PhoneNumber[] {
		const formatPhoneNumber = (p: CommAddressDisplayDto) => {
			let parts = [];
			let hasCallingCode;
			if (p.callingCode) {
				parts.push(`+${p.callingCode}`);
				hasCallingCode = true;
			}

			if (p.areaCode) {
				let areaCode = p.areaCode;
				if (areaCode[0] === '0' && hasCallingCode) {
					areaCode = areaCode.substr(1);
				}
				parts.push(areaCode);
			}

			if (p.number) {
				let num = p.number;
				if (hasCallingCode && num[0] === '0') {
					num = num.substr(1);
				}
				parts.push(num);
			}

			let result = parts.join(' ');
			let regex = /\s/gi;
			let numResult = result.replace(regex, '');

			return {
				number: numResult,
				text: result,
			};
		};
		return (appointment.phoneNumbers || []).map((p) => formatPhoneNumber(p));
	}

	getProfessional(id: number): string {
		const prof = this.professionalsById[id];
		if (!prof) {
			return '';
		}
		return prof.name;
	}

	onSelect(appointment: AppointmentDto) {
		this.loading = true;
		this.router.navigate(['/appointments', appointment.appointmentId]);
	}

	getSessionNumber(appointment: AppointmentDto) {
		if (appointment.excludeAsSession) {
			return '';
		}

		let maxStr = '';
		if (appointment.maxSessions < intMaxValue) {
			maxStr = `(${appointment.maxSessions})`;
		}

		return appointment.sessionNumber + maxStr;
	}

	isPrivate(appointment: AppointmentDto) {
		return appointment.appointmentType === AppointmentType.Private;
	}

	isActivity(appointment: AppointmentDto) {
		return appointment.appointmentType === AppointmentType.Activity;
	}

	isLeave(appointment: AppointmentDto) {
		return appointment.appointmentType === AppointmentType.Leave;
	}

	countNonLeaveAppointments(day: Day): number {
		const count = day.appointments.filter((a) => a.appointmentType !== AppointmentType.Leave).length;
		return count;
	}

	private handleDashboardData(data: AppointmentsDto) {
		this.appointments = data;
		this.loading = false;

		this.view = this.buildApointmentsView(data);
	}

	private buildApointmentsView(appointments: AppointmentsDto): Day[] {
		const appointmentsCollection = _.cloneDeep(appointments.appointments);

		// Find leave appointments that cross multiple days.
		const multiDayLeaves = appointmentsCollection.filter((a) => {
			if (a.appointmentType !== AppointmentType.Leave) {
				return false;
			}
			let start = moment(a.dateTimeStart).startOf('day');
			let end = moment(a.dateTimeEnd).startOf('day');

			return !start.isSame(end, 'day');
		});

		const startDate = this.dashboardService.getDashboardAppointmentsStartDate();
		const endDate = this.dashboardService.getDashboardAppointmentsEndDate(this.numAppointmentDays);

		// Generate an appointment entry for each day across the date range.
		multiDayLeaves.forEach((leaveAppointment) => {
			// Remvoe the original multi-day leave appointment.
			appointmentsCollection.splice(appointmentsCollection.indexOf(leaveAppointment), 1);

			let rangeStartDate = moment(leaveAppointment.dateTimeStart);
			if (rangeStartDate.isBefore(startDate)) {
				rangeStartDate = startDate;
			}
			let rangeEndDate = moment(leaveAppointment.dateTimeEnd);
			if (rangeEndDate.isAfter(endDate)) {
				rangeEndDate = endDate;
			}

			let date = moment(rangeStartDate);
			while (!date.isAfter(rangeEndDate)) {
				const isFirstDate = date.isSame(rangeStartDate, 'date');
				const isLastDate = date.isSame(rangeEndDate, 'date');

				const cloned = _.cloneDeep(leaveAppointment);
				if (moment(cloned.dateTimeStart).isBefore(date, 'date')) {
					// tslint:disable-next-line:no-string-literal
					cloned['continuing'] = true;
					if (isFirstDate) {
						// tslint:disable-next-line:no-string-literal
						cloned['sinceDateTimeStart'] = cloned.dateTimeStart;
					}
				}
				cloned.dateTimeStart = date.toDate();

				if (moment(cloned.dateTimeEnd).startOf('day').isAfter(date)) {
					if (isLastDate) {
						// tslint:disable-next-line:no-string-literal
						cloned['toDateTimeEnd'] = cloned.dateTimeEnd;
					}
					cloned.dateTimeEnd = date.endOf('day').toDate();
				}
				// Add the cloned appointment.
				appointmentsCollection.push(cloned);

				date.add(1, 'day').startOf('day');
			}
		});

		const keyFormat = 'YYYYMMDD';
		const sorted = _.sortBy(appointmentsCollection, (a) => a.dateTimeStart);
		const byDay = _.groupBy(sorted, (a) => moment(a.dateTimeStart).startOf('date').format(keyFormat));

		const today = moment().startOf('date').clone(),
			numDays = _.keys(byDay).length,
			dayNames = ['Today', 'Tomorrow'],
			result = [] as Day[];

		for (let i = 0; i < Math.max(appointments.numDays, numDays); i++) {
			const date = moment(today).add(i, 'day').startOf('day'),
				day = {
					date: date.toDate(),
				} as Day;

			result.push(day);
			day.name = i < dayNames.length ? dayNames[i] : date.format('dddd');

			day.appointments = byDay[date.format(keyFormat)] || [];
		}

		return result;
	}
}
