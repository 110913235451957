import {
	StatisticalTypeDto,
	StatisticalChoiceDto,
	StatisticDto,
	StatisticHeaderEditDto,
} from './../../../services/server-data';
import { BehaviorSubject, Observable } from 'rxjs';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { StatisticContainerDto } from '../../../services';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

let newId = -1;

@Component({
	selector: 'pp-container-pick-and-add-two-num',
	templateUrl: './container-pick-and-add-two-num.component.html',
	styleUrls: ['./container-pick-and-add-two-num.component.scss'],
})
export class ContainerPickAndAddTwoNumComponent implements OnInit {
	private allChoices: StatisticalChoiceDto[];

	@Input() container: StatisticContainerDto;
	@Input() header: StatisticHeaderEditDto;

	@Output() changed = new EventEmitter<boolean>();

	statistics$ = new BehaviorSubject<StatisticDto[]>(null);
	type: StatisticalTypeDto;

	availableChoices$: Observable<StatisticalChoiceDto[]>;

	valueUpdated() {
		this.changed.emit(true);
	}

	statisticChanged(stat: StatisticDto) {
		this.valueUpdated();
	}

	statisticDeleted(stat: StatisticDto) {
		const index = this.container.statistics.indexOf(stat);
		this.container.statistics.splice(index, 1);
		this.statistics$.next(this.container.statistics);

		this.valueUpdated();
	}

	ngOnInit() {
		this.type = this.container.statisticalGroup.statisticalTypes[0];
		this.statistics$.next(this.container.statistics);

		this.allChoices = this.type.statisticalChoices;

		this.availableChoices$ = this.statistics$.pipe(
			map((stats) => {
				return stats.map((s) => s.statisticalChoiceId);
			}),
			map((choiceIDs) => {
				return this.allChoices.filter((c) => !c.isEditBox && !choiceIDs.includes(c.id));
			}),
			map((choices) => {
				return _.sortBy(choices, (c) => c.description);
			})
		);
	}

	addChoice(choiceId) {
		const newStat = {
			//			id: newId--,
			statisticalChoiceId: choiceId,
			statisticHeaderId: this.header.id,
			statisticalTypeId: this.type.id,
			int: 0,
			intExtra1: 0,
		} as StatisticDto;
		this.container.statistics.push(newStat);
		this.statistics$.next(this.container.statistics);
	}
}
