import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class SelectedCaseService {
	activityHeaderId: number;
	selectedCaseChanged$ = new EventEmitter();

	onSelectedCaseChanged(newId: number) {
		this.activityHeaderId = newId;
		this.selectedCaseChanged$.emit();
	}
}
