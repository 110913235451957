import { Component, OnInit, Input, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {
	StatisticHeaderListDto,
	AgGridService,
} from 'app/services';
import { SelectionGridComponent } from 'app/shared/selection-grid/selection-grid.component';

import { CasesService } from 'app/cases/cases.service';

@Component({
	selector: 'pp-headers',
	templateUrl: './headers.component.html',
	styleUrls: ['./headers.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadersComponent implements OnInit {
	columnDefs: any[];

	@Input() headers: StatisticHeaderListDto[];
	@ViewChild('grid', { static: true }) grid: SelectionGridComponent;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private agGrid: AgGridService,
		private service: CasesService,
	) { }

	ngOnInit() {
		this.setUpAgGrid();
	}

	edit(e: StatisticHeaderListDto) {
		this.router.navigate(['header', {id: e.id }], { relativeTo: this.route });
	}

	private setUpAgGrid() {
		this.columnDefs = [
			this.agGrid.makeDateColumn('Period start', 'startDateTime'),
			this.agGrid.makeDateColumn('Period end', 'endDateTime'),
			{ headerName: 'Professional', field: 'professionalName', width: 175, maxWidth: 250 },
		];

		this.grid.subscribe(this.service.statisticHeadersChanged$);
	}
}
