import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { SaveResultDto } from 'app/services';

export function respondsTo(obj: object, fnName): boolean {
	return typeof obj[fnName] === 'function';
}

export interface ICheckSaveChanges {
	getObjectName?: () => string;
	getComponents?: () => (IForm | IEditComponent)[];
	hasChanges?: () => boolean;
	saveChanges?: (obj: object, confirmWarning?: boolean) => Observable<any>;
	discardChanges?: () => void;

	/**
	 * Optionally returns whether the form should show progress.
	 * @returns {boolean} True if the component shows save progress.
	 * @memberof IEditComponent
	 */
	showsProgress?: () => boolean;

	/**
	 * Optionally returns an Observable of number which shows progress.
	 */
	progress?: () => Observable<number>;

	/**
	 * Must return the object to update and save.
	 * @memberof ICheckSaveChanges
	 */
	getObject: () => object;
}

/**
 * Interface for edit components for checking for validity,
 * whether there are changes and applying changes to the DTO.
 * @export
 * @interface IEditComponent
 */
export interface IEditComponent {
	/**
	 * Optionally returns whether the form is valid (or DISABLED)
	 * @returns {boolean} True if the form could be saved.
	 * @memberof IEditComponent
	 */
	isValid?(): boolean;

	/**
	 * Optionally returns whether the form should show progress.
	 * @returns {boolean} True if the component shows save progress.
	 * @memberof IEditComponent
	 */
	showsProgress?(): boolean;

	/**
	 * Optionally returns an Observable of number which shows progress.
	 */
	progress?(): Observable<number>;

	/**
	 * Optionally returns whether the form has changes.
	 * @returns {boolean} True if the form is dirty.
	 * @memberof IEditComponent
	 */
	hasChanges?(): boolean;

	/**
	 * Applies changes from the form to the object.
	 * @param {*} obj The object to update.
	 * @memberof IEditComponent
	 */
	applyChanges?(obj: any);

	/**
	 * Saves changes - save the supplied object.
	 * @param {object} obj The object to save.
	 * @returns {Observable<any>} An obsersable returning the result of the save.
	 * @memberof IEditComponent
	 */
	saveChanges?(obj: object, confirmWarning?: boolean): Observable<SaveResultDto<any>>;

	/**
	 * Optionally provide the object to be updated and saved.
	 * @memberof ICheckSaveChanges
	 */
	getObject?(): object;

	/**
	 * Optionally provide a means of marking the component clean.
	 */
	markClean?();
}

/**
 * Extension interface for IEditComponent for components that
 * contain a FormGroup.
 * @export
 * @interface IForm
 * @extends {IEditComponent}
 */
export interface IForm extends IEditComponent {
	/**
	 * Returns the FormGroup.
	 * @returns {FormGroup} The FormGroup object.
	 * @memberof IForm
	 */
	getForm(): FormGroup;
}
