// tslint:disable:no-bitwise

import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

import {
	AppointmentDto,
	AvailabilityDto,
	AvailabilityEditDto,
	dateToAmbiguousMoment,
	AvailabilityStartTimeEditDto
} from 'app/services';

export interface IAppointmentEvent {
	id: number;
	title: string;
	start: moment.Moment;
	end: moment.Moment;
	overlap: boolean;
	appointment: AppointmentDto;
	resourceId: string;
	startEditable: boolean;
	droppable: boolean;
	durationEditable: boolean;
	editable: boolean;
	updating: boolean;
	rendering: string;
	available?: boolean;
	isStartTime?: boolean;
	allDay?: boolean;
	isRecurring?: boolean;
	isReadOnly?: boolean;
	className?: string;
	isTechOnly: boolean;
}

export class DateRange {
	constructor(public dateStart: Date, public dateEndExclusive: Date) {}

	equals(otherRange): boolean {
		if (!otherRange) {
			return false;
		}

		return (
			moment(otherRange.dateStart).isSame(this.dateStart, 'day') &&
			moment(otherRange.dateEndExclusive).isSame(this.dateEndExclusive, 'day')
		);
	}
}

export class DiaryIdDayOrDateKey {
	constructor(public diaryId: number, public dayOfWeekOrDate: number) {}

	toString(): string {
		return `id:${this.diaryId} d:${this.dayOfWeekOrDate}`;
	}
}

export class DiaryIdDate {
	constructor(public diaryId: number, public date: Date) {}
}

export enum DiaryType {
	Office,
	Professional
}
export class Diary {
	id: number;
	name: string;
	diaryType: DiaryType;
}

export class Resource {
	id: number;
	title: string;
	msTimeZoneId: string;
	professionalId: number;
}

export const msPerDay = 24 * 60 * 60 * 1000;
export const msPerMinute = 60 * 1000;
export const bitsPerByte = 8;

export const AGENDA_DAY = 'agendaDay';
export const AGENDA_WEEK = 'agendaWeek';

export const VIEW_DAY = 'day';
export const VIEW_WEEK = 'week';

export const SLOT_MINUTES = 15;

// TODO: For evaluation only - replace with commercial license.
export const schedulerLicenseKey = 'CC-Attribution-NonCommercial-NoDerivatives';

const debug = false;

@Injectable()
export class CalendarCommonService {
	private eventId = -1;

	applyAvailabilityEdit(availability: AvailabilityDto, edit: AvailabilityEditDto) {
		const editStart = moment(edit.startTime);
		const editEnd = moment(edit.endTime);

		const startOfday = editStart.clone().startOf('day');
		const startMinute = editStart.diff(startOfday, 'minutes');
		const endMinute = editEnd.diff(startOfday, 'minutes');

		const firstByteIndex = Math.floor(startMinute / bitsPerByte);
		const firstByteBits = startMinute % bitsPerByte;

		const lastByteIndex = Math.floor(endMinute / bitsPerByte);
		const lastByteBits = bitsPerByte - (endMinute % bitsPerByte);  //doesn't seem right but is
		//const lastByteBits = endMinute % bitsPerByte;

		const availabilityBytes = atob(availability.timeSlotsBase64);
		let firstByte = availabilityBytes.charCodeAt(firstByteIndex);
		let mask = ((0xff << firstByteBits) >> bitsPerByte) & 0xff;

		if (edit.isAvailable) {
			firstByte = firstByte & mask;
		} else {
			mask = ~mask & 0xff;
			firstByte = firstByte | mask;
		}

		let lastByte = availabilityBytes.charCodeAt(lastByteIndex);
		mask = (0xff00 >> lastByteBits) & 0xff;
		if (edit.isAvailable) {
			lastByte = lastByte & mask;
		} else {
			mask = ~mask & 0xff;
			lastByte = lastByte | mask;
		}

		let middle;
		let middleStart = firstByteIndex + 1;
		let middleEnd = lastByteIndex - 1;

		let start = availabilityBytes.substring(0, firstByteIndex) || '';
		let end = availabilityBytes.substring(lastByteIndex + 1) || '';
		if (edit.isAvailable) {
			const char = String.fromCharCode(0);
			middle = _.repeat(char, middleEnd - middleStart + 1);
		} else {
			const char = String.fromCharCode(0xff);
			middle = _.repeat(char, middleEnd - middleStart + 1);
		}

		const newBytes = start + String.fromCharCode(firstByte) + middle + String.fromCharCode(lastByte) + end;

		const base64 = btoa(newBytes);
		availability.timeSlotsBase64 = base64;
	}

	applyAvailabilityTechOnlyEdit(availability: AvailabilityDto, edit: AvailabilityEditDto) {

		const editStart = moment(edit.startTime);
		const editEnd = moment(edit.endTime);

		const startOfday = editStart.clone().startOf('day');
		const startMinute = editStart.diff(startOfday, 'minutes');
		const endMinute = editEnd.diff(startOfday, 'minutes');

		const firstByteIndex = Math.floor(startMinute / bitsPerByte);
		const firstByteBits = startMinute % bitsPerByte;

		const lastByteIndex = Math.floor(endMinute / bitsPerByte);
		const lastByteBits = bitsPerByte - (endMinute % bitsPerByte);  //doesn't seem right but is

		const disallowInPersonBytes = atob(availability.disallowInPersonBase64);

		let firstByte = disallowInPersonBytes.charCodeAt(firstByteIndex);
		//let mask = ((0xff << firstByteBits) >> bitsPerByte) & 0xff;
		let mask = (0xff << firstByteBits) & 0xff;
		if (edit.techOnly) {
			firstByte = firstByte | mask;
		}
		else
		{
			//firstByte = 0x00;
			mask = ~mask & 0xff;
			firstByte = firstByte & mask;
		}

		let lastByte = disallowInPersonBytes.charCodeAt(lastByteIndex);
		mask = (0xff >> lastByteBits) & 0xff;
		if (edit.techOnly) {	
			lastByte = lastByte | mask;
		}
		else
		{
			mask = ~mask & 0xff;
			lastByte = lastByte & mask;
		}
		
		let middle;
		let middleStart = firstByteIndex + 1;
		let middleEnd = lastByteIndex - 1;

		let start = disallowInPersonBytes.substring(0, firstByteIndex) || '';
		let end = disallowInPersonBytes.substring(lastByteIndex + 1) || '';
		
		if (edit.techOnly) {
			const char = String.fromCharCode(0xff);
			middle = _.repeat(char, middleEnd - middleStart + 1);
		}
		else
		{
			const char = String.fromCharCode(0x00);
			middle = _.repeat(char, middleEnd - middleStart + 1);
		}

		const newBytes = start + String.fromCharCode(firstByte) + middle + String.fromCharCode(lastByte) + end;

		const base64 = btoa(newBytes);

		availability.disallowInPersonBase64 = base64;
	}

	applyAvailabilityStartTimeEdit(availability: AvailabilityDto, edit: AvailabilityStartTimeEditDto) {
		const editStart = moment(edit.startTime);
		const editEnd = moment(edit.startTime).add(15, 'minutes');

		const startOfday = editStart.clone().startOf('day');
		const startMinute = editStart.diff(startOfday, 'minutes');
		const endMinute = editEnd.diff(startOfday, 'minutes');

		const firstByteIndex = Math.floor(startMinute / bitsPerByte);
		const firstByteBits = startMinute % bitsPerByte;

		const lastByteIndex = Math.floor(endMinute / bitsPerByte);
		const lastByteBits = bitsPerByte - (endMinute % bitsPerByte);

		const startTimesBytes = atob(availability.startTimesBase64);
		let firstByte = startTimesBytes.charCodeAt(firstByteIndex);
		let mask = ((0xff << firstByteBits) >> bitsPerByte) & 0xff;
		if (!edit.isStartTime) {
			firstByte = firstByte & mask;
		} else {
			mask = ~mask & 0xff;
			firstByte = firstByte | mask;
		}

		let lastByte = startTimesBytes.charCodeAt(lastByteIndex);
		mask = (0xff00 >> lastByteBits) & 0xff;
		if (!edit.isStartTime) {
			lastByte = lastByte & mask;
		} else {
			mask = ~mask & 0xff;
			lastByte = lastByte | mask;
		}

		let middle;
		let middleStart = firstByteIndex + 1;
		let middleEnd = lastByteIndex - 1;

		let start = startTimesBytes.substring(0, firstByteIndex) || '';
		let end = startTimesBytes.substring(lastByteIndex + 1) || '';
		if (!edit.isStartTime) {
			const char = String.fromCharCode(0);
			middle = _.repeat(char, middleEnd - middleStart + 1);
		} else {
			const char = String.fromCharCode(0xff);
			middle = _.repeat(char, middleEnd - middleStart + 1);
		}

		// if (debug) {
		// 	console.log(`Start ${start.length} middle ${middle.length} end ${end.length}`);
		// }

		const newBytes = start + String.fromCharCode(firstByte) + middle + String.fromCharCode(lastByte) + end;

		// if (debug) {
		// 	console.log('Length: ' + newBytes.length);
		// 	for (let i = 0; i < newBytes.length; i++) {
		// 		let byte = newBytes.charCodeAt(i);
		// 		console.log(`${i} ${byte}`);
		// 	}
		// }

		const base64 = btoa(newBytes);
		availability.startTimesBase64 = base64;
	}

	/**
	 * Debugging method to dump out (to the console) a display of time ranges
	 * and whether the bit it set for the range.
	 * @param base64 The bit-minute map of times of the day.
	 */
	dumpBase64TimesMap(base64: string) {
		const bytes = atob(base64);

		let forDate = moment().startOf('day');
		let thisMinute = moment(forDate);

		// Determine the flag status at the start of the day.
		let lastFlag = (bytes.charCodeAt(0) & 0xff) === 1;

		let events = [];

		const newEvent = (startDate: moment.Moment, flag: boolean) => {
			let event = {
				start: startDate.clone(),
				end: startDate.clone(),
				flag
			};
			events.push(event);
			return event;
		};

		// Build the event for the start of the date.
		let currentEvent = newEvent(forDate, lastFlag);

		// Loop through every minute of the day determining whether its bit is set or not
		//  and when it changes state, end the last event and begin a new event.
		for (let byteIndex = 0; byteIndex < bytes.length; byteIndex++) {
			let byte: number = bytes.charCodeAt(byteIndex);

			for (let bitIndex = 0; bitIndex < bitsPerByte; bitIndex++) {
				// Next minute is in the least significant bit.

				let flag = (byte & 0x01) === 1;

				// Check if it's changed state - if so, end the current event and begin a new one.
				if (flag !== lastFlag) {
					currentEvent.end = thisMinute.clone();

					// Build a new event
					currentEvent = newEvent(thisMinute, flag);
					lastFlag = flag;
				}

				byte = byte >> 1;

				// Move on to the next minute.
				thisMinute = moment(thisMinute).add(1, 'minute');
			}
		}

		// Close off the current event with the end time being the start of the next day.
		currentEvent.end = forDate.clone().add(1, 'day');

		// Dump out the generated events.
		events.forEach(e => {
			console.log(`${e.start.format('HH:mm')} - ${e.end.format('HH:mm')}: ${e.flag ? '1' : '0'}`);
		});
	}

	buildAvailabilityEvents(
		dateRange: DateRange,
		diaryIDs: number[],
		availabilities: AvailabilityDto[],
		otherEvents: IAppointmentEvent[] = []
	): IAppointmentEvent[] {
		// Build an array of dates in the date range, start to end exclusive.
		const datesInRange = _.range(dateRange.dateStart.getTime(), dateRange.dateEndExclusive.getTime(), msPerDay).map(
			ms => new Date(ms)
		);

		// Build hashes for the availabilities keyed by the diary ID and date, categoriesed as recurring or specific.
		const recurringAvailabilities = {};
		const specificDateAvailibilities = {};

		availabilities.forEach(a => {
			if (a.isRecurring) {
				const dayOfWeek = moment(a.forDate).day();
				const key = new DiaryIdDayOrDateKey(a.bookingDiaryId, dayOfWeek).toString();
				recurringAvailabilities[key] = a;
			} else {
				const key = new DiaryIdDayOrDateKey(a.bookingDiaryId, a.forDate.getTime()).toString();
				specificDateAvailibilities[key] = a;
			}
		});

		// Build an array of DiaryIdDate objects - one object for each diary ID for each date in the range.
		const diaryIdDates = <DiaryIdDate[]>datesInRange.reduce((dates: DiaryIdDate[], date) => {
			for (let i = 0; i < diaryIDs.length; i++) {
				const id = diaryIDs[i];
				dates.push(new DiaryIdDate(id, date));
			}

			return dates;
		}, []);

		// Produce an array of objects for each DiaryIdDate combination and the availability object for that combo.
		const availabilitiesByDiaryIdDate = diaryIdDates.map(diaryIdDate => {
			const dayOfWeek = moment(diaryIdDate.date).day();
			const dateMs = moment(diaryIdDate.date)
				.startOf('day')
				.toDate()
				.getTime();

			// Build keys to look up specific and recurring availabilities.
			const dayOfWeekKey = new DiaryIdDayOrDateKey(diaryIdDate.diaryId, dayOfWeek).toString();
			const dateKey = new DiaryIdDayOrDateKey(diaryIdDate.diaryId, dateMs).toString();

			// Try to find a specific and recurring availability.
			const specific = specificDateAvailibilities[dateKey];
			const recurring = recurringAvailabilities[dayOfWeekKey];

			// Return an object with the DiaryIdDate object and the found availability (specific overriding the recurring).
			return {
				diaryIdDate,
				availability: specific ? specific : recurring
			};
		});

		// Produce a combined list of appointment events and background events (for availabilities) by building background events
		// for each diary ID and date combo.
		let events = availabilitiesByDiaryIdDate.reduce((list, diaryIdDateAvailability) => {
			const diaryIdDate = diaryIdDateAvailability.diaryIdDate;
			const availability = diaryIdDateAvailability.availability;

			// Build background events for the available portions of the availability spec for the diary ID and date.
			let backgroundEvents = this.buildAvailabilityBackgroundEvents(
				diaryIdDate.diaryId,
				diaryIdDate.date,
				availability
			);

			// Concatenate to the existing list (the reduce part).
			return _.concat(list, backgroundEvents);
		}, otherEvents);

		return events;
	}

	private nextEventId(): number {
		return this.eventId--;
	}

	private buildAvailabilityBackgroundEvents(
		diaryId: number,
		date: Date,
		availability: AvailabilityDto
	): IAppointmentEvent[] {
		let availabilityEvents: IAppointmentEvent[] = [];

		const forDate = dateToAmbiguousMoment(date).startOf('day');

		// Define a function for creating new events and adding them to the result list.
		const newEvent = (startDate: moment.Moment, available: boolean, isStartTime?: boolean, isTechOnly?: boolean) => {
			let event = <IAppointmentEvent>{
				id: this.nextEventId(),
				start: startDate.clone(),
				end: startDate.clone(),
				title: '',
				overlap: true,
				resourceId: '' + diaryId,
				startEditable: false,
				durationEditable: false,
				rendering: 'background',
				appointment: undefined,
				available: available,
				isStartTime: isStartTime,
				isTechOnly : isTechOnly,
				className: isStartTime ? 'start-time' :  isTechOnly ? 'tech-only' : 'background-event'
			};
			availabilityEvents.push(event);
			return event;
		};

		if (!availability) {
			return availabilityEvents;
		}

		const recurringDate = forDate.clone();

		// Build an all-day event to represent the recurring status.
		const allDayEvent = newEvent(recurringDate, false);
		allDayEvent.allDay = true;
		allDayEvent.isRecurring = availability.isRecurring;
		allDayEvent.rendering = '';
		allDayEvent.end = allDayEvent.start.clone().add(1, 'day');

		/*
		 * The availability info is base-64 encoded 180 byte array with 1 bit per minute of the day,
		 * with a 1 signifying available, and a 0 signifying unavailable.
		 */
		const availabilityBytes = atob(availability.timeSlotsBase64);

		/*
		 * Same for start times - signified by a 1 bit at the start time.
		 */
		const startTimesBytes = atob(availability.startTimesBase64);

		/*
		 * Same for disallow in person times - signified by a 1 bit at the start time.
		 */
		const disallowInPersonBytes = atob(availability.disallowInPersonBase64);

		// Get the first minute status - is it availabile or unavailable?

		// tslint:disable-next-line:no-bitwise
		let lastIsAvailable = (availabilityBytes.charCodeAt(0) & 0xff) === 0;

		// Get the first minute start time status - is it flagged as a start time?
		let lastIsStartTime = (startTimesBytes.charCodeAt(0) & 0xff) === 1;

		// Get the first minute start time status - is it flagged as a start time?
		let lastIsTechOnly = (disallowInPersonBytes.charCodeAt(0) & 0xff) === 1;

		// Build the event for the start of the date.
		let currentEvent = newEvent(forDate, lastIsAvailable);
		//let techOnlyCurrentEvent = newEvent(forDate, lastIsAvailable);
		let techOnlyCurrentEvent:IAppointmentEvent;

		let thisMinute = moment(forDate);

		// Loop through every minute of the day determining whether it's available or unavailable, or when it's
		// a start time and when it changes state, end the last event and begin a new event.
		for (let byteIndex = 0; byteIndex < availabilityBytes.length; byteIndex++) {
			let byte: number = availabilityBytes.charCodeAt(byteIndex);
			let startTimesByte: number = startTimesBytes.charCodeAt(byteIndex);
			let disallowInPersonByte: number = disallowInPersonBytes.charCodeAt(byteIndex);

			for (let bitIndex = 0; bitIndex < bitsPerByte; bitIndex++) {
				// Next minute is in the least significant bit.

				let isAvailable = (byte & 0x01) === 0;
				let isStartTime = (startTimesByte & 0x01) === 1;
				let isTechOnly= (disallowInPersonByte & 0x01) !== 0;

				// Check if it's changed state - if so, end the current event and begin a new one.
				if (isAvailable !== lastIsAvailable || isStartTime !== lastIsStartTime) {
					currentEvent.end = thisMinute.clone();

					// Build a new event
					currentEvent = newEvent(thisMinute, isAvailable, isStartTime && isAvailable);
					lastIsAvailable = isAvailable;
					lastIsStartTime = isStartTime;
				}

				if ((isTechOnly !== lastIsTechOnly)) { 

					if (isTechOnly)
					{
						// Build a new event
						techOnlyCurrentEvent = newEvent(thisMinute, true, false, true);
					}
					else
					{
						techOnlyCurrentEvent.end = thisMinute.clone();
					}
				}

				lastIsTechOnly = isTechOnly;

				byte = byte >> 1;
				startTimesByte = startTimesByte >> 1;
				disallowInPersonByte = disallowInPersonByte >> 1;

				// Move on to the next minute.
				thisMinute = moment(thisMinute).add(1, 'minute');
			}
		}

		// Close off the current event with the end time being the start of the next day.
		currentEvent.end = forDate.clone().add(1, 'day');

		// Finally, we only want background events where the professional is available, or is marked as a start time.
		// The unavailabilities should leave gaps.
		availabilityEvents = availabilityEvents.filter(e => !e.available || e.isStartTime || e.isTechOnly);

		return availabilityEvents;
	}
}
