import { SelectedCaseService } from './../common-editing/selected-case.service';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TdFileService, TdDialogService } from '@covalent/core';
import * as moment from 'moment';
import * as _ from 'lodash';

import { BaseService } from 'app/services/base-service';

import {
	OpenActivityHeadersDto,
	ActivityHeaderEditLoadDto,
	ActivityHeaderEditDto,
	ActivityEntryListInfoDto,
	ActivityEntryInfoDto,
	StatisticHeaderEditDto,
	StatisticHeaderListDto,
	StatisticClientEditDto,
	StatisticClientListDto,
	ActivityNoteInfoDto,
	ActivityNoteEditDto,
	NoteAttachmentInfoDto,
	NoteAttachmentEditDto,
	ChangeNotification,
	SaveResult,
	SaveResultDto,
	CommonService,
	AuthenticationService,
	JsonService,
	toUnspecified
} from 'app/services';

@Injectable()
export class CasesService extends BaseService {
	currentLoadDto: ActivityHeaderEditLoadDto;
	currentEditDto: ActivityHeaderEditDto;

	statisticClientsChanged$ = new EventEmitter<ChangeNotification<StatisticClientListDto>>();
	statisticHeadersChanged$ = new EventEmitter<ChangeNotification<StatisticHeaderListDto>>();
	activityNoteChanged$ = new EventEmitter<ChangeNotification<ActivityNoteInfoDto>>();
	noteAttachmentsChanged$ = new EventEmitter<ChangeNotification<NoteAttachmentInfoDto>>();

	saveNoteRequested$ = new EventEmitter<object>();
	noteSaved$ = new EventEmitter<ActivityNoteEditDto>();

	uploadProgress$ = new BehaviorSubject<number>(0);

	constructor(
		common: CommonService,
		private json: JsonService,
		private fileService: TdFileService,
		private dialog: TdDialogService,
		private selectedCaseService: SelectedCaseService,
		private authenticationService: AuthenticationService
	) {
		super(common);

		this.fileService.progress.subscribe(v => {
			this.uploadProgress$.next(v);
		});
	}

	get professionalId(): number {
		return this.config.professionalId;
	}

	getCases(): Observable<OpenActivityHeadersDto> {
		return this.crud.loadWithGet<OpenActivityHeadersDto>('api/openActivityHeaders');
	}

	getCase(activityHeaderId: number): Observable<ActivityHeaderEditLoadDto> {
		return this.crud
			.loadWithGet<ActivityHeaderEditLoadDto>(`api/activityHeaders/edit/${activityHeaderId}`)
			.pipe(map(dto => this.onLoaded(dto)));
	}

	getActivityEntries(activityHeaderId: number): Observable<ActivityEntryListInfoDto[]> {
		return this.crud.loadWithGet<ActivityEntryInfoDto[]>(`api/activityHeaders/activityEntries/${activityHeaderId}`);
	}

	getActivityEntry(activityHeaderId: number, activityEntryId: number): Observable<ActivityEntryInfoDto> {
		return this.crud.loadWithGet<ActivityEntryInfoDto>(
			`api/activityHeaders/activityEntry/${activityHeaderId}/${activityEntryId}`
		);
	}

	saveCase(editDto: ActivityHeaderEditDto) {
		return this.save('api/activityHeaders/save', editDto);
	}

	//
	// StatisticClient
	//

	getStatisticClient(activityHeaderId: number, statisticClientId: number): Observable<StatisticClientEditDto> {
		return this.crud.loadWithGet<StatisticClientEditDto>(
			`api/activityHeaders/statisticClient/${activityHeaderId}/${statisticClientId}`
		);
	}

	statisticClientSave(activityHeaderId: number, statisticClient: StatisticClientEditDto) {
		const url = `api/activityHeaders/statisticClient/save/${activityHeaderId}`;
		return this.save(url, statisticClient, this.statisticClientsChanged$);
	}

	statisticClientDelete(activityHeaderId: number, statisticClient: StatisticClientEditDto) {
		const url = `api/activityHeaders/statisticClient/${activityHeaderId}/${statisticClient.id}`;
		this.delete(url, statisticClient.id, this.statisticClientsChanged$, 'client');
	}

	//
	// StatisticHeader
	//

	getStatisticHeader(activityHeaderId: number, statisticHeaderId: number): Observable<StatisticHeaderEditDto> {
		return this.crud.loadWithGet<StatisticHeaderEditDto>(
			`api/activityHeaders/statisticHeader/${activityHeaderId}/${statisticHeaderId}`
		);
	}

	statisticHeaderSave(activityHeaderId: number, statisticHeader: StatisticHeaderEditDto) {
		const url = `api/activityHeaders/statisticHeader/save/${activityHeaderId}`;
		return this.save(url, statisticHeader, this.statisticHeadersChanged$);
	}

	///
	/// Activity Note
	///

	requestSaveNote() {
		this.saveNoteRequested$.emit();
	}

	notifyNoteSaved(dto: ActivityNoteEditDto) {
		this.noteSaved$.emit(dto);
	}

	getActivityNote(activityHeaderId: number, noteId: number): Observable<ActivityNoteEditDto> {
		return this.crud.loadWithGet<ActivityNoteEditDto>(
			`api/activityHeaders/activityNote/${activityHeaderId}/${noteId}`
		);
	}

	activityNoteSave(activityHeaderId: number, editDto: ActivityNoteEditDto) {
		const cloned = _.cloneDeep(editDto);
		cloned.noteDateTime = toUnspecified(editDto.noteDateTime);
		const url = `api/activityHeaders/activityNote/save/${activityHeaderId}`;
		return this.saveInfo(url, cloned, this.activityNoteChanged$);
	}

	activityNoteDelete(activityHeaderId: number, dto: ActivityNoteInfoDto) {
		const url = `api/activityHeaders/activityNote/${activityHeaderId}/${dto.id}`;
		this.delete(url, dto.id, this.activityNoteChanged$, 'note');
	}

	///
	/// Note Attachment
	///

	getNoteAttachment(
		activityHeaderId: number,
		noteId: number,
		attachmentId: number
	): Observable<NoteAttachmentEditDto> {
		return this.crud.loadWithGet<NoteAttachmentEditDto>(
			`api/activityHeaders/activityNoteFileAttachment/${activityHeaderId}/${noteId}/${attachmentId}`
		);
	}

	uploadFile(
		f: File,
		activityHeaderId: number,
		noteId: number,
		attachmentId: number,
		fileName: string
	): Observable<SaveResultDto<NoteAttachmentInfoDto>> {
		const sub = new Subject<SaveResultDto<NoteAttachmentInfoDto>>();

		const fd = new FormData();
		fd.append('file', f);
		fd.append('activityHeaderId', '' + activityHeaderId);
		fd.append('noteId', '' + noteId);
		fd.append('attachmentId', '' + (attachmentId || -1));
		fd.append('fileName', fileName);

		const token = this.authenticationService.getToken();

		// Reset the progres
		this.uploadProgress$.next(0);

		this.fileService
			.upload({
				url: 'api/activityHeaders/attachment/upload',
				method: 'post',
				formData: fd,
				headers: {
					Authorization: 'Bearer ' + token,
					Accept: 'application/json'
				}
			})
			.pipe(map(responseText => this.json.jsonFromString(responseText) as SaveResultDto<NoteAttachmentInfoDto>))
			.subscribe(
				result => {
					switch (result.saveResult) {
						case SaveResult.OK:
							this.noteAttachmentsChanged$.emit(ChangeNotification.update(result.resultDto));
							break;
						case SaveResult.Error:
							this.dialog.openAlert({
								message: result.errorMessage,
								title: 'Save failed'
							});
							break;
						case SaveResult.Warnings:
							break;
						default:
							break;
					}

					sub.next(result);
				},
				err => {
					sub.error(err);
				}
			);

		return sub;
	}

	noteAttachmentDelete(activityHeaderId: number, noteId: number, dto: NoteAttachmentInfoDto) {
		const url = `api/activityHeaders/attachment//${activityHeaderId}/${noteId}/${dto.id}`;
		this.delete(url, dto.id, this.noteAttachmentsChanged$, 'attachment');
	}

	noteAttachmentSave(activityHeaderId: number, noteId: number, attachment: NoteAttachmentEditDto) {
		const url = `api/activityHeaders/activityNoteFileAttachment/save/${activityHeaderId}/${noteId}`;
		return this.saveInfo(url, attachment, this.noteAttachmentsChanged$);
	}

	onLoaded(loadDto: ActivityHeaderEditLoadDto): ActivityHeaderEditLoadDto {
		this.currentLoadDto = loadDto;
		this.currentEditDto = loadDto.editDto;

		this.selectedCaseService.onSelectedCaseChanged(loadDto.editDto.activityHeaderId);

		return loadDto;
	}

	onActivityStatusChanged(
		loadDto: ActivityHeaderEditLoadDto,
		editDto: ActivityHeaderEditDto,
		statusId: number,
		priorActivityStatusId: number,
		priorDateClosed?: Date
	) {
		const status = loadDto.activityStatuses.find(s => s.id === statusId),
			oldStatus = loadDto.activityStatuses.find(s => s.id === priorActivityStatusId);

		loadDto.isReferredToEditable = status.isReferredOn;
		if (status.isClosed && !oldStatus.isClosed) {
			// Set the closed date to today if it wasn't previously closed.
			const dateClosed =
				priorDateClosed ||
				moment()
					.startOf('day')
					.toDate();
			editDto.dateClosed = dateClosed;
		} else if (!status.isClosed) {
			// Clear the closed date if setting to not closed
			editDto.dateClosed = undefined;
		}
	}
}
