import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
	StatisticContainerDto
} from 'app/services';

@Component({
	selector: 'pp-container-two-num',
	templateUrl: './container-two-num.component.html',
	styleUrls: ['./container-two-num.component.scss']
})
export class ContainerTwoNumComponent {
	@Input() container: StatisticContainerDto;
	@Output() changed = new EventEmitter<boolean>();

	valueUpdated() {
		this.changed.emit(true);
	}
}
