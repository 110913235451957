
import {switchMap, distinctUntilChanged, debounceTime, filter} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable ,  Subscription } from 'rxjs';

import {
	GlobalSearchResultItemDto,
	ItemType
} from 'app/services';

import { CasesSearchService } from './cases-search.service';

import { SearchFilterComponent } from 'app/shared/search-filter/search-filter.component';
import { TextComponent } from 'app/shared/forms/text.component';

@Component({
	selector: 'pp-case-search',
	templateUrl: './case-search.component.html',
	styleUrls: ['./case-search.component.scss']
})
export class CaseSearchComponent implements OnInit, OnDestroy {
	private subSearch: Subscription;

	// Template properties
	form: FormGroup;
	items: Observable<GlobalSearchResultItemDto[]>;

	@ViewChild('filter', { static: true }) filter: SearchFilterComponent;
	@ViewChild('search', { static: true }) search: TextComponent;

	@Output() resultSelected = new EventEmitter<void>();

	constructor(
		private fb: FormBuilder,
		private router: Router,
		public searchService: CasesSearchService,
	) {
		this.buildForm();
	}

	ngOnInit() {
		const svc = this.searchService;
		this.form.patchValue({
			searchText: svc.searchText,
			dateFrom: svc.dateFrom,
			dateTo: svc.dateTo,
			openOnly: svc.openOnly,
			showAll: svc.showAll,
		});

		this.subSearch = this.form.valueChanges.pipe(
						debounceTime(300),
						distinctUntilChanged(),
						switchMap(v => {
							return svc.search(v.searchText, v.showAll, v.dateFrom, v.dateTo, v.openOnly, true);
						}),).subscribe();
	}

	ngOnDestroy() {
		if (this.subSearch) {
			this.subSearch.unsubscribe();
		}
	}

	setFocus() {
		this.search.setFocus();
	}

	onSelect(item: GlobalSearchResultItemDto) {
		switch (item.itemType) {
			case ItemType.ActivityHeader:
				const inCase = /\/cases\/[0-9]+/.test(this.router.url);
				let additional;
				if (inCase) {
					additional = { replaceUrl: true };
				}

				this.searchService.setSearching(true);
				this.router.navigate(['/cases', item.id], additional)
					.then(() => {
						this.resultSelected.emit();
						this.searchService.setSearching(false);
					})
					.catch(() => this.searchService.setSearching(false));
				break;
			default:
				break;
		}
	}

	private buildForm() {
		this.form = this.fb.group({
			searchText: undefined,
			dateFrom: undefined,
			dateTo: undefined,
			openOnly: undefined,
			showAll: undefined,
		});
	}
}
