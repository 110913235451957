import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'breakify'
})
export class BreakifyPipe implements PipeTransform {
	transform(value: string, args?: any): any {
		if (!value) {
			return '';
		}
		let result = value.replace(/\r\n/g, '</br>');
		return result;
	}
}
