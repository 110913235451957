import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class LogoutService {
	loggingOut: boolean;

	constructor(private router: Router) {}

	forceLogin() {
		this.loggingOut = true;
		this.router.navigate(['/login']);
	}
}
