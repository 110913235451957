export class DialogResult<T> {
	ok: boolean;
	result?: T;

	static ok<T>(result: T): DialogResult<T>  {
		return <DialogResult<T>> {
			ok: true,
			result
		};
	}

	static cancel<T>(): DialogResult<T> {
		return <DialogResult<T>> {
			ok: false
		};
	}
}
