import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { TdDialogService } from '@covalent/core';
import * as _ from 'lodash';

import { LoggerService } from './logger.service';
import { CommonService } from './common.service';

@Injectable()
export class FormsService {
	constructor(
		private location: Location,
		private logger: LoggerService,
		private dialog: TdDialogService,
		private common: CommonService,
	) { }

	/**
	 * Generically handles an error loading something, displaying a message to the user and then
	 * sending the browser location back.
	 * @param {*} err The error
	 * @param {string} typeName  The name of the type being loaded.
	 * @memberof FormsService
	 */
	handleLoadError(err: any, typeName: string) {
		if (this.common.isAlreadyHandledError(err)) {
			return;
		}

		typeName = typeName || 'object';
		let title = 'Load error';

		let message;
		if (err instanceof Error || err.message) {
			message = err.message;
		}

		if (err.title) {
			title = err.title;
		}

		if (err instanceof HttpErrorResponse) {
			if (err.status === 500) {
				message = '';
			} else {
				if (err.error) {
					try {
						const errorResult = JSON.parse(err.error);
						if (errorResult && errorResult.errors && errorResult.errors.length) {
							message = errorResult.errors[0];
						}
					} catch (ex) {
						// Ignore
					}
				}
			}
		}

		if (!message) {
			message = `A server error occurred loading the ${_.lowerFirst(typeName)}.`;
			this.logger.logError(message, err);
		}

		this.dialog.openAlert({
			message,
			title: title
		}).afterClosed().subscribe(() => {
			this.location.back();
		});
	}
}
