import { UtilsService } from './../../services/utils.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Component, Input, forwardRef, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseComponent } from './base-component';

let identifier = 0;

@Component({
	selector: 'pp-display',
	template: `
		<div class="form-component" fxLayout="column">
			<span class="label">{{ label }}</span>
			<span class="display-value" *ngIf="!multiLineStringConvertToHtml">
				{{ value }}
				<ng-content></ng-content>
			</span>
			<span class="display-value" *ngIf="multiLineStringConvertToHtml" [innerHtml]="innerHtml"> </span>
		</div>
	`,
	styleUrls: ['./display.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DisplayComponent), multi: true }]
})
export class DisplayComponent extends BaseComponent<string> implements OnChanges {
	@Input() label: string;
	@Input() value: any;
	@Input() multiLineStringConvertToHtml: boolean;

	innerHtml: SafeHtml;

	public identifier = `display-${identifier++}`;

	constructor(private domSanitizer: DomSanitizer, private utils: UtilsService) {
		super();
	}

	ngOnChanges(changes) {
		if (changes.value) {
			if (this.multiLineStringConvertToHtml) {
				const converted = this.utils.breakify(this.value);
				this.innerHtml = this.domSanitizer.bypassSecurityTrustHtml(converted);
			}
		}
	}
}
