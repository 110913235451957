import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CheckSaveChangesService } from 'app/services';
import { Subscription } from 'rxjs';

@Component({
	selector: 'pp-apply-cancel',
	templateUrl: './apply-cancel.component.html',
	styleUrls: ['./apply-cancel.component.scss']
})
export class ApplyCancelComponent implements OnInit, OnDestroy  {
	private newIsEnabled: boolean;

	@Input() disabledWhileSaving: boolean = true;
	@Input() active: boolean = true;
	@Input() asSave: boolean;
	@Input() form?: FormGroup;
	@Input() readOnly: boolean;
	@Output() result = new EventEmitter<boolean>();

	isEnabled: boolean = true;
	subscriber: Subscription;

	constructor(
		private saveChanges: CheckSaveChangesService
	) {	}

	ngOnInit() {
		this.isEnabled = true;
		if (this.disabledWhileSaving) {
			this.subscriber = this.saveChanges.inProgress$.subscribe(inProgress => {
				this.newIsEnabled = !inProgress;
				if (inProgress) {
					this.isEnabled = false;
				} else {
					setTimeout(() => {
						this.isEnabled = this.newIsEnabled;
					}, 250);
				}
			});
		}
	}

	ngOnDestroy() {
		if (this.subscriber) {
			this.subscriber.unsubscribe();
		}
	}

	apply() {
		this.result.emit(true);
	}

	cancel() {
		this.result.emit(false);
	}

	get isDisabled() {
		return (this.form && !this.form.valid) || !this.active || this.readOnly || !this.isEnabled;
	}
}
