import {
	Component,
	OnInit,
	OnChanges,
	Input,
	Output,
	forwardRef,
	ViewChild,
	ElementRef,
	Renderer,
	EventEmitter,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { BaseComponent } from './base-component';

let identifier = 0;

@Component({
	selector: 'pp-text',
	template: ` <div class="form-component">
		<div fxLayout="row">
			<mat-form-field fxFlex>
				<input
					matInput
					#inputElement
					[id]="identifier"
					[type]="type"
					[placeholder]="placeholder"
					[maxlength]="maxLength"
					[disabled]="isDisabled"
					[required]="required"
					[(ngModel)]="value"
					[ngClass]="{ invalid: !isValid }"
					[textMask]="{ mask: textMask }"
					[min]="minValue"
					(blur)="onBlur()"
					(focus)="onFocus()"
					(keydown)="onKeyDown($event)"
					(paste)="onPaste($event)"
				/>
			</mat-form-field>
		</div>
		<div class="validation-message" *ngIf="control?.touched && control.hasError('required')">
			{{ label }} is required
		</div>
	</div>`,
	styleUrls: ['./text.component.scss'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextComponent), multi: true },
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => TextComponent), multi: true },
	],
})
export class TextComponent extends BaseComponent<string> implements OnInit, OnChanges {
	textMask: any = false;

	@Input() label: string;
	@Input() type = 'text';
	@Input() maxLength = 512 * 1024; // 512K
	@Input() placeholder = '';
	@Input() numericOnly = false;
	@Input() decimalPlaces = 0;
	@Input() maxIntDigits = 10;
	@Input() allowNegative: boolean = false;
	@Input() tabIndex: number = undefined;

	@Output() blur = new EventEmitter<TextComponent>();
	@Output() focus = new EventEmitter<TextComponent>();

	identifier = `text-${identifier++}`;

	minValue = '';

	@ViewChild('inputElement', { static: true }) inputEl: ElementRef;

	constructor(private renderer: Renderer) {
		super();
	}

	onBlur() {
		this.touch();
		this.blur.emit(this);
	}

	onFocus() {
		this.focus.emit(this);
	}

	onKeyDown(event: KeyboardEvent) {
		if (this.numericOnly && !this.allowNegative) {
			// Prevent typing a -ve number
			if (event.key === '-') {
				event.preventDefault();
				return;
			}
		}
	}

	onPaste(e) {
		if (this.numericOnly && !this.allowNegative) {
			// Prevent pasting a -ve value in.
			const clipboardData = e.clipboardData || e.originalEvent.clipboardDataa;
			const pastedData = clipboardData.getData('text');

			if (isNaN(pastedData)) {
				e.preventDefault();
				return;
			}

			const num = Number(pastedData).valueOf();
			if (num < 0) {
				e.preventDefault();
				return;
			}
		}
	}

	ngOnInit() {
		if (this.tabIndex) {
			this.renderer.setElementAttribute(this.inputEl.nativeElement, 'tabIndex', this.tabIndex + '');
		}

		if (!this.placeholder) {
			this.placeholder = this.label;
		}

		if (this.numericOnly) {
			let params: any = {
				includeThousandsSeparator: false,
				prefix: '',
				integerLimit: this.maxIntDigits,
			};

			if (this.decimalPlaces > 0) {
				params.allowDecimal = true;
				params.decimalLimit = this.decimalPlaces;
			}

			params.allowNegative = this.allowNegative;

			if (!this.allowNegative) {
				this.minValue = '0';
			}

			this.textMask = createNumberMask(params);
			this.type = 'number';
		}
	}

	ngOnChanges(inputs) {
		if (!inputs.placeholder) {
			this.placeholder = this.label || '';
		}
	}

	setFocus() {
		this.inputEl.nativeElement.focus();
		this.inputEl.nativeElement.select();
		if (this.value && this.type === 'text') {
			setTimeout(() => {
				this.inputEl.nativeElement.setSelectionRange(0, (this.value + '').length + 1);
			});
		}
	}
}
