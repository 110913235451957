import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivityHeaderExtendedDto, IClientEditDto, IClientEditLoadDto } from 'app/services';
import { moment } from 'fullcalendar';
import { ExtendedDetails, ExtendedDetailsHelper, ExtendedDetailsItemsDisplay } from '../extended-details';
import { ExtendedDetailsEditOtherComponent } from '../extended-details-edit-other/extended-details-edit-other.component';
import {ExtendedDetailsItemsDisplayComponent } from '../extended-details-items-display/extended-details-items-display.component';

@Component({
  selector: 'pp-extended-details-edit',
  templateUrl: './extended-details-edit.component.html',
  styleUrls: ['./extended-details-edit.component.scss']
})
export class ExtendedDetailsEditComponent implements OnInit {

  extendedDetailsLoaded: boolean;
  @Input() loadDto: IClientEditLoadDto;
	@Input() editDto: IClientEditDto;  //change
	@Input() readOnly: boolean;
  
  @ViewChild(ExtendedDetailsEditOtherComponent, { static: false })
  extendedDetailsEditSecondaryComponent: ExtendedDetailsEditOtherComponent;

  @ViewChild(ExtendedDetailsItemsDisplayComponent, { static: false })
  extendedDetailsItemsDisplayComponent: ExtendedDetailsItemsDisplayComponent;

  extendedDetails: ExtendedDetails = new ExtendedDetails (); 

  constructor() { 
  }


  ngOnInit() {
  }

  ngOnChanges(changes) {
		if (changes.loadDto && this.loadDto) {
      this.populateExtendedDetails(changes.loadDto.currentValue.activityHeaderExtended);
		}

    if (this.extendedDetailsLoaded && changes.editDto.currentValue) {

      //rename to activityHeaderExtended from activityHeaderExtendedDto to make consistent -- also on update
      this.populateExtendedDetails(changes.editDto.currentValue.activityHeaderExtended);
      
    }
  }

  private populateExtendedDetails(activityHeaderExtended:ActivityHeaderExtendedDto)
  {
    this.extendedDetails.extendedDetailsItems =[];
    this.addExtendedDetailsItem("Red Flag Status",activityHeaderExtended.isRedFlag,true);
    this.addExtendedDetailsItem("Prefer Private Transport",activityHeaderExtended.isPreferPrivateTransport);
    this.addExtendedDetailsItem("Prefer Public Transport",activityHeaderExtended.isPreferPublicTransport);
    this.addExtendedDetailsItem("Has Used Service Before",activityHeaderExtended.hasUsedServiceBefore);
    this.addExtendedDetailsItem("Review Each Session",activityHeaderExtended.isReviewForEachSession);
    this.addExtendedDetailsItem("Review Final Session",activityHeaderExtended.isReviewForFinalSession);

    this.extendedDetails.medicationsTaken = activityHeaderExtended.medicationTaken;
    this.extendedDetails.disabilities= activityHeaderExtended.disabilitiesToConsider;
    this.extendedDetails.createDateTime = activityHeaderExtended.redFlagStatusDate;
    this.extendedDetails.usedProfessional = activityHeaderExtended.usedProfessional;
    this.extendedDetails.usedService = activityHeaderExtended.usedService;
    this.extendedDetails.usedServiceMonth = activityHeaderExtended.usedServiceMonth;
    this.extendedDetails.usedServiceMonthFormatted = moment(this.extendedDetails.usedServiceMonth).format('MMMM YYYY') ;

    let index:number = activityHeaderExtended.redFlagAlternativeAppearance;
    index = (index === null) ? 0 :index;
    this.extendedDetails.redFlagColour = ExtendedDetailsHelper.redFlagAlternativeAppearenceColours[index];
    this.extendedDetails.hasUsedServiceBefore = activityHeaderExtended.hasUsedServiceBefore;

    this.updateChanges(this.extendedDetails,true,true);
  }

  private addExtendedDetailsItem(text:string, state:boolean,displayImage:boolean = false)
  {
    let extendedDetailsItemsDisplayDto:ExtendedDetailsItemsDisplay= new ExtendedDetailsItemsDisplay();
    extendedDetailsItemsDisplayDto.text = text;
    extendedDetailsItemsDisplayDto.value = state;
    extendedDetailsItemsDisplayDto.displayImage = displayImage;
    this.extendedDetails.extendedDetailsItems.push(extendedDetailsItemsDisplayDto);

  }

  private secondaryDetailsChanged(editDto:ExtendedDetails)
  {
    this.extendedDetailsItemsDisplayComponent.updateColour(editDto.redFlagColour);
    this.updateChanges(editDto,false,true);
  }

  private itemsChanged(editDto:ExtendedDetails)
  {
    this.extendedDetails = editDto;
    if(editDto.extendedDetailsItems[3].value !=  editDto.hasUsedServiceBefore)  //has used service before
    {
      editDto.hasUsedServiceBefore = editDto.extendedDetailsItems[3].value;
      this.extendedDetailsEditSecondaryComponent.changeHasUsedServiceBefore(editDto.hasUsedServiceBefore);
    }
    this.updateChanges(editDto,true,false)
  }

  private updateChanges(editDto:ExtendedDetails, updateItems:boolean, updateOther:boolean)
  {
    if (updateItems)
    {
      this.editDto.activityHeaderExtended.isRedFlag = editDto.extendedDetailsItems[0].value;
      this.editDto.activityHeaderExtended.isPreferPrivateTransport = editDto.extendedDetailsItems[1].value;
      this.editDto.activityHeaderExtended.isPreferPublicTransport = editDto.extendedDetailsItems[2].value;
      this.editDto.activityHeaderExtended.hasUsedServiceBefore = editDto.extendedDetailsItems[3].value;
      this.editDto.activityHeaderExtended.isReviewForEachSession = editDto.extendedDetailsItems[4].value;
      this.editDto.activityHeaderExtended.isReviewForFinalSession = editDto.extendedDetailsItems[5].value;

    }
    if (updateOther)
    { 
      this.editDto.activityHeaderExtended.medicationTaken = editDto.medicationsTaken; 
      this.editDto.activityHeaderExtended.disabilitiesToConsider = editDto.disabilities; 
      this.editDto.activityHeaderExtended.usedProfessional = editDto.usedProfessional;
      this.editDto.activityHeaderExtended.usedService = editDto.usedService;
      
      if (editDto.usedServiceMonth != null)
      {

        let offset = editDto.usedServiceMonth.getTimezoneOffset();
        this.editDto.activityHeaderExtended.usedServiceMonth = moment(editDto.usedServiceMonth).add(0-offset,'minutes').toDate();
      }

      let index = ExtendedDetailsHelper.redFlagAlternativeAppearenceColours.findIndex(c => c === editDto.redFlagColour);
      if (index == 0) index = null; //red
      this.editDto.activityHeaderExtended.redFlagAlternativeAppearance = index;
    }

    this.extendedDetailsLoaded =true;

  }
}


