import {
		Component,
		OnChanges,
		Input,
		Output,
		EventEmitter,
		ChangeDetectionStrategy,
		ChangeDetectorRef,
		ViewChild,
		ViewChildren,
		QueryList,
		ElementRef,
} from '@angular/core';
import * as _ from 'lodash';
import {
	MatDialog
} from '@angular/material';

import {
	StatisticalTypeDto,
	StatisticalChoiceDto,
	StatisticContainerDto,
	StatisticDto,
} from 'app/services';

import { AddChoiceModalComponent } from '../add-choice-modal/add-choice-modal.component';
import { EditorTwoNumComponent } from 'app/common-editing/statistics-editors/editor-two-num/editor-two-num.component';

@Component({
	selector: 'pp-container-two-num-plus-minus-edit',
	templateUrl: './container-two-num-plus-minus-edit.component.html',
	styleUrls: ['./container-two-num-plus-minus-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerTwoNumPlusMinusEditComponent implements OnChanges {
	statsForType: StatisticDto[];
	choices: StatisticalChoiceDto[];

	@Input() type: StatisticalTypeDto;
	@Input() statistics: StatisticDto[];
	@Input() container: StatisticContainerDto;
	@Output() changed = new EventEmitter<boolean>();

	@ViewChild('modalArea', { static: true }) modalAreaEl: ElementRef;
	@ViewChildren(EditorTwoNumComponent) editors: QueryList<EditorTwoNumComponent>;

	constructor(
		private cdRef: ChangeDetectorRef,
		private dialog: MatDialog,
	) { }

	ngOnChanges() {
		if (this.type && this.statistics) {
			this.statsForType = this.statistics.filter(s => s.statisticalTypeId === this.type.id);
			const choiceIDs = this.statsForType.map(s => s.statisticalChoiceId);
			this.choices = this.type.statisticalChoices.filter(c => choiceIDs.indexOf(c.id) > -1);
		}
	}

	getStatistic(choice: StatisticalChoiceDto): StatisticDto {
		const statistic = this.statsForType.find(s => s.statisticalChoiceId === choice.id);
		return statistic;
	}

	positive(choice: StatisticalChoiceDto): number {
		const stat = this.statsForType.find(s => s.statisticalChoiceId === choice.id);
		return stat ? stat.int : 0;
	}

	negative(choice: StatisticalChoiceDto): number {
		const stat = this.statsForType.find(s => s.statisticalChoiceId === choice.id);
		return stat ? stat.intExtra1 : 0;
	}

	valueChanged() {
		this.changed.emit(true);
	}

	addEntry(type: StatisticalTypeDto) {
		const data = {
			choices: _.differenceBy(this.type.statisticalChoices, this.choices),
			choice: undefined
		};

		const rect = this.modalAreaEl.nativeElement.getBoundingClientRect();
		let dialogRef = this.dialog.open(AddChoiceModalComponent, {
			width: rect.width + 'px',
			position: {
				left: rect.left + 'px',
				top: rect.top + 'px',
			},
			data
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result === 'ok') {
				const choice = <StatisticalChoiceDto> data.choice;
				const stat = <StatisticDto> {
					statisticalChoiceId: choice.id,
					statisticalTypeId: type.id,
					int: 0,
					intExtra1: 0
				};
				this.statistics.push(stat);
				this.statsForType.push(stat);
				this.choices.push(choice);

				this.cdRef.detectChanges();

				const newEditor = this.editors.last;
				newEditor.setFocus();
			}
		});
	}
}
