import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import * as _ from 'lodash';
import {
	StatisticContainerDto,
	StatisticalTypeDto,
	StatisticDto,
} from 'app/services';

@Component({
	selector: 'pp-container-pick-list-grid',
	templateUrl: './container-pick-list-grid.component.html',
	styleUrls: ['./container-pick-list-grid.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerPickListGridComponent implements OnInit {
	hasChoiceLimit: boolean;
	canAdd: boolean;
	choiceLimitWarningText: string;
	types: StatisticalTypeDto[];

	@Input() container: StatisticContainerDto;
	@Output() changed = new EventEmitter<StatisticDto>();

	ngOnInit() {
		this.types = this.container.statisticalGroup.statisticalTypes;
		this.hasChoiceLimit = !!(this.container.statisticalGroup.isMultiChoice && this.container.statisticalGroup.choiceLimit);
		if (this.hasChoiceLimit) {
			this.choiceLimitWarningText = 'You can select up to a maximum total of ' +
				`${this.container.statisticalGroup.choiceLimit} across ${this.types[0].description}`;

			for (let i = 1; i < this.types.length; i++) {
				this.choiceLimitWarningText += ` and ${this.types[i].description}`;
			}
		}

		this.checkLimit();
	}

	pickerChanged(statistic: StatisticDto) {
		this.checkLimit();
		this.changed.emit(statistic);
	}

	private checkLimit() {
		if (!this.hasChoiceLimit) {
			this.canAdd = true;
			return;
		}
		const statisticCount = this.container.statistics.filter(s => !s.isEmpty).length;
		this.canAdd = statisticCount < this.container.statisticalGroup.choiceLimit;
	}
}
