import { Injectable, isDevMode } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { retryWhen, delay, tap, map, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class HttpRetryInterceptor implements HttpInterceptor {
	constructor(private snackBar: MatSnackBar) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// tslint:disable-next-line: variable-name
		const Max_Retries = 5;

		return next.handle(request).pipe(
			catchError((err) => {
				if (!isDevMode()) {
					return throwError(err);
				}

				// In development mode, handle a 4xx error with a snackbar message
				if (err instanceof HttpErrorResponse) {
					if (err.status >= 404 && err.status < 500) {
						this.show400SeriesNotification(err.status, request.method, request.url);
						return EMPTY;
					}
				}
				return throwError(err);
			}),
			retryWhen((err) => {
				let retries = 1;
				return err.pipe(
					// Build the notification observable for retrying.

					// Wait for a second
					map((error) => {
						// 4xx error or 500 - just throw - no retry
						if (error.status >= 400 && error.status <= 500) {
							throw error;
						}

						// Keep retrying until we reach the max (or we succeed)
						if (retries++ === Max_Retries) {
							throw error;
						}

						return error;
					}),
					// Retrying - so delay for a brief period
					delay(1000),
					// Show a notification that we're retrying
					tap(() => {
						this.showRetryToast(retries, Max_Retries);
					})
				);
			}),
			catchError((err) => {
				// Give up - rethrow the error
				return throwError(err);
			})
		);
	}

	private showRetryToast(numTries: number, maxRetries: number) {
		this.snackBar.open(`Something went wrong - retrying (${numTries}/${maxRetries})`, '', {
			duration: 3000,
		});
	}

	private show400SeriesNotification(status: number, method: string, url: string) {
		this.snackBar.open(`Got a ${status} error for a ${method} to ${url}`, 'Close');
	}
}
