import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// If the request is a POST and has a body wiht a JSON object, add the Content-Type header.
		if (request.method === 'POST' && /^\{/.test(request.body)) {
			request = request.clone({
				headers: request.headers.set('Content-Type', 'application/json'),
			});
		}

		return next.handle(request);
	}
}
