import { Injectable } from '@angular/core';

const debugDateConvert = false;

function convertDate(body: {}, key: string, element: string) {
	// Check for a MS-format Date with optional TZ offset.
	const matched = matchDate(element);
	if (matched) {
		const localDate = convertMatchToLocalDate(key, matched);
		// Set the date.
		body[key] = localDate;
	}
}

function matchDate(element: string): RegExpExecArray {
	const matched = /\/Date\(([-]?\d{1,15})([\+-][01][0-3][0-5][0-9])?\)\//.exec(element);
	return matched;
}

function convertMatchToLocalDate(key: string, matched: RegExpExecArray): Date {
	let tzOffsetMs = 0;
	const tz = matched[2] || '';
	if (tz) {
		const hours = parseInt(tz.substr(0, tz.length - 2), 10);
		const mins = parseInt(tz.substr(tz.length - 2, 2), 10);
		const tzOffsetMins = mins + hours * 60;
		tzOffsetMs = tzOffsetMins * 60 * 1000;
	}

	// Create a UTC Date object.
	const date = new Date(+matched[1] + tzOffsetMs);
	// Get the timezone offset for the local time (in minutes) and convert to ms.
	const tzOffset = date.getTimezoneOffset() * 60 * 1000;

	// Create a local date by using the offset
	let localDate = new Date(date.getTime() + tzOffset);

	// Check for a changeover of DST. If the timezone offsets are different then DST has changed over
	// so adjust the final date by the DST change amount.
	const dst = (localDate.getTimezoneOffset() * 60 * 1000) - tzOffset;
	if (dst) {
		localDate = new Date(localDate.getTime() + dst);
	}

	if (debugDateConvert) {
		console.log(date + ' - ' + key + ': local: ' + localDate.toISOString() + ' ' + localDate + ' dst: ' + dst);
	}

	return localDate;
}

@Injectable()
export class JsonService {
	jsonFromString(data: string): any {
		return JSON.parse(data, this.reviveDateTime);
	}

	stringify(data: any): string {
		return JSON.stringify(data);
	}

	recurseResponseBody(body: {}) {
		if (!body) {
			return;
		}

		for (let key in body) {
			if (body.hasOwnProperty(key)) {
				let element = body[key];
				if (typeof element === 'object') {
					this.recurseResponseBody(element);
				} else if (typeof element === 'string') {
					convertDate(body, key, element);
				}
			}
		}
	}

	reviveDateTime(key: string, value: any): any {
		if (typeof value === 'string') {
			// Check for a MS-format Date with optional TZ offset.
			const matched = matchDate(value);
			if (matched) {
				return convertMatchToLocalDate(key, matched);
			}
		}

		return value;
	}
}
