import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
	StatisticalTypeDto,
	StatisticDto,
} from 'app/services';
import { StatisticEditorBase } from '../statistic-editor-base';

@Component({
	selector: 'pp-editor-numeric-textbox-dec',
	templateUrl: './editor-numeric-textbox-dec.component.html',
	styleUrls: ['./editor-numeric-textbox-dec.component.scss']
})
export class EditorNumericTextboxDecComponent extends StatisticEditorBase implements OnInit {
	form: FormGroup;

	constructor(
		private fb: FormBuilder,
	) {
		super();
	}

	ngOnInit() {
		this.buildForm();
		this.form.patchValue({
			dec: this.statistic.dec
		});

		this.form.controls.dec.valueChanges.subscribe(val => {
			this.statistic.dec = +val;
			this.emitChange();
		});
	}

	private buildForm() {
		this.form = this.fb.group({
			dec: undefined
		});

		if (this.type.isRequired) {
			this.form.controls.dec.setValidators(Validators.required);
		}
	}
}
