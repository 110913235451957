import { EapServicesClientSurveyDto } from './../../../services/server-data';
import { BaseService } from 'app/services/base-service';
import { BehaviorSubject } from 'rxjs';
import { SelectedCaseService } from './../../selected-case.service';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CommonService } from 'app/services';
import { finalize, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class EapServicesClientQuestionnaireService extends BaseService {
	private static FORCE_REFRESH_AFTER_SECONDS = 1;
	private lastRefreshed: Date;
	private isFinal: boolean;

	refreshing$ = new BehaviorSubject<boolean>(false);
	questionnaire$ = new BehaviorSubject<EapServicesClientSurveyDto>(null);

	constructor(common: CommonService, private selectedCaseService: SelectedCaseService) {
		super(common);

		// Subscribe to the selected case changing - reset our last refreshed time.
		this.selectedCaseService.selectedCaseChanged$.subscribe(() => {
			this.reset();
		});
	}

	reset() {
		this.lastRefreshed = null;
		this.isFinal = false;
		this.questionnaire$.next(null);
	}

	onActivated() {
		// If already loaded and marked as final, do nothing.
		if (this.isFinal) {
			return;
		}

		let sinceLastRefreshed;
		if (this.lastRefreshed) {
			sinceLastRefreshed = moment().diff(this.lastRefreshed, 'seconds');
		}

		if (
			!sinceLastRefreshed ||
			sinceLastRefreshed > EapServicesClientQuestionnaireService.FORCE_REFRESH_AFTER_SECONDS
		) {
			this.refresh();
			this.lastRefreshed = moment().toDate();
		}
	}

	forceRefresh() {
		this.refresh();
	}

	private refresh() {
		if (this.refreshing$.value) {
			return;
		}

		this.refreshing$.next(true);

		const activityHeaderId = this.selectedCaseService.activityHeaderId;
		this.crud
			.loadWithGet<EapServicesClientSurveyDto>(`api/activityHeaders/statistics/custom/${activityHeaderId}`)
			.pipe(
				tap((questionnaire: EapServicesClientSurveyDto) => {
					this.isFinal = questionnaire && questionnaire.isFinal;
					this.questionnaire$.next(questionnaire);
				}),
				finalize(() => this.refreshing$.next(false))
			)
			.subscribe();
	}
}
