import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { StatisticHeaderListDto, StatisticClientListDto } from 'app/services';

import { CasesService } from 'app/cases/cases.service';
import { IEditComponent } from 'app/interfaces';

@Component({
	selector: 'pp-statistic-headers',
	templateUrl: './statistic-headers.component.html',
	styleUrls: ['./statistic-headers.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticHeadersComponent implements IEditComponent {
	@Input() headers: StatisticHeaderListDto[];
	@Input() clients: StatisticClientListDto[];
	@Input() hasHeaders: boolean;
	@Input() hasClients: boolean;

	constructor(private service: CasesService) {}

	hasChanges(): boolean {
		return false;
	}
}
