import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from '../services/authentication.service';
import { LogoutService } from '../services/logout.service';

@Injectable()
export class LogoutGuard implements CanActivate {
	
	constructor(private jwtHelper: JwtHelperService, 
		private logout: LogoutService,
		private authenticationService: AuthenticationService
		) {}

	canActivate() {
		// Only allow logout if the user clicked the logout button.
		if (this.logout.loggingOut) {
			return true;
		}

		if (this.isAuthenticated()) {
			return false;
		}

		return true;
	}

	isAuthenticated() {
		const token = this.authenticationService.getToken();

		if (token) {
			try {
				if (this.jwtHelper.isTokenExpired(token)) {
					return false;
				}
			} catch (e) {
				return false;
			}
		}

		return !!sessionStorage.getItem(AuthenticationService.KEY_CURRENT_USER);
	}
}
