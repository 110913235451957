import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'pp-modal-dialog-header',
	templateUrl: './modal-dialog-header.component.html',
	styleUrls: ['./modal-dialog-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalDialogHeaderComponent {
	@Input() title: string;
}
