import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Title } from '@angular/platform-browser';

@Component({
	selector: 'pp-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
	constructor(private _titleService: Title,
		private router: Router) {
	}

	ngOnInit(): void {
		this._titleService.setTitle('Professional Portal - Dashboard');
	}
}
