import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
	StatisticContainerDto,
	StatisticalTypeDto,
	StatisticDto,
} from 'app/services';

class StatisticWithType {
	statistic: StatisticDto;
	type: StatisticalTypeDto;
}

@Component({
	selector: 'pp-container-controls',
	templateUrl: './container-controls.component.html',
	styleUrls: ['./container-controls.component.scss']
})
export class ContainerControlsComponent implements OnInit {
	statistics: StatisticWithType[];

	@Input() container: StatisticContainerDto;
	@Output() changed = new EventEmitter<StatisticContainerDto>();

	ngOnInit() {
		this.buildStatistics();
	}

	valueUpdated(statistic: StatisticDto) {
		this.changed.emit(this.container);
	}

	private buildStatistics() {
		const group = this.container.statisticalGroup;
		this.statistics = this.container.statistics.map(
			s => {
				return {
					statistic: s,
					type: group.statisticalTypes.find(t => t.id === s.statisticalTypeId)
				};
			}
		);
	}
}
