import { appMsalConfig, msalDynamicConfig } from './../msal.config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { of } from 'rxjs';

export interface IConfig {
	customisations?: ICustomisation;
	options?: IOptions;
	ADB2C?: IActiveDirectoryB2CConfig;
}

export interface ICustomisation {
	toolbarBackgroundColor?: string;
	toolbarColor: string;
	appTitle?: string;
	iconSvg?: string;
	logoSvg?: string;
	showCopyright?: boolean;
}

export interface IOptions {
	enableB2cLogin?: boolean;
	enableB2cCorporateLogin?: boolean;
	disableLegacyLogin?: boolean;
}

export interface IActiveDirectoryB2CConfig {
	clientId: string;
	scopes?: string;
	signInAuthority: string;
	signInAuthorityCorp: string;
	passwordResetAuthority: string;
	redirectUri: string;
}

@Injectable({
	providedIn: 'root'
})
export class CustomisationService {
	customisation = {} as ICustomisation;
	options = {} as IOptions;
	adB2cConfig = {} as IActiveDirectoryB2CConfig;

	constructor(private http: HttpClient, private title: Title) {}

	loadConfigurationData(): Promise<IConfig> {
		return this.http
			.get<IConfig>('./assets/app-config.json')
			.pipe(
				tap(r => {
					const customisations = r.customisations;
					const adB2CConfig = r.ADB2C;
					const options = r.options;

					if (adB2CConfig) {
						this.adB2cConfig = r.ADB2C;

						// Update the dynamic MSAL config object with the values returned from the config JSON.
						msalDynamicConfig.clientId = adB2CConfig.clientId;
						msalDynamicConfig.signInAuthority = adB2CConfig.signInAuthority;
						msalDynamicConfig.signInAuthorityCorp = adB2CConfig.signInAuthorityCorp;
						msalDynamicConfig.passwordResetAuthority = adB2CConfig.passwordResetAuthority;
						msalDynamicConfig.redirectUri = adB2CConfig.redirectUri;
					}

					if (customisations) {
						this.customisation = customisations;

						if (customisations && customisations.appTitle) {
							this.title.setTitle(customisations.appTitle);
						}
					}

					if (options) {
						this.options = options;
					}
				}),
				catchError(() => {
					// Handle any error as having no customisation.
					// Likely to be a 404.
					return of({} as IConfig);
				})
			)
			.toPromise();
	}
}
