import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UnmanagedCookieService } from 'ng2-cookies';
import { VirtualScrollModule } from 'angular2-virtual-scroll';

// Covalent modules
import {
	CovalentCommonModule,
	CovalentDialogsModule,
	CovalentFileModule,
	CovalentLayoutModule,
	CovalentLoadingModule,
	CovalentMessageModule,
} from '@covalent/core';

// Angular material modules
import {
	MatAutocompleteModule,
	MatButtonModule,
	MatButtonToggleModule,
	MatCardModule,
	MatCheckboxModule,
	MatChipsModule,
	MatDatepickerModule,
	MatDialogModule,
	MatExpansionModule,
	MatIconModule,
	MatInputModule,
	MatListModule,
	MatMenuModule,
	MatProgressSpinnerModule,
	MatProgressBarModule,
	MatRadioModule,
	MatNativeDateModule,
	MatSelectModule,
	MatSidenavModule,
	MatSlideToggleModule,
	MatSnackBarModule,
	MatTabsModule,
	MatToolbarModule,
	MatTooltipModule,
} from '@angular/material';

// Misc 3rd party modules
import { AgGridModule } from 'ag-grid-angular/main';
import { TextMaskModule } from 'angular2-text-mask';
import { QuillModule } from 'ngx-quill';
import { SplitPaneModule } from 'ng2-split-pane/lib/ng2-split-pane';

// Local directives
import { BlockNonNumberDirective } from './block-non-number.directive';
import { EqualValidatorDirective } from './equal-validator/equal-validator.directive';
import { FocusDirective } from './focus/focus.directive';

// Local reusable components
import { ApplyCancelComponent } from './apply-cancel/apply-cancel.component';
import { ButtonComponent } from './forms/button.component';
import { ButtonToggleComponent } from './forms/button-toggle.component';
import { CheckboxComponent } from './forms/checkbox.component';
import { CaseSearchItemComponent } from './case-search-item/case-search-item.component';
import { DateComponent } from './forms/date-component';
import { DisplayComponent } from './forms/display.component';
import { HtmlEditorComponent } from './forms/html-editor.component';
import { IconButtonComponent } from './forms/icon-button.component';
import { RadioGroupComponent } from './forms/radio-group.component';
import { SelectComponent } from './forms/select.component';
import { SelectionGridComponent } from './selection-grid/selection-grid.component';
import { SwitchComponent } from './forms/switch.component';
import { TextAreaComponent } from './forms/text-area.component';
import { TextComponent } from './forms/text.component';
import { TextPlainComponent } from './forms/text-plain.component';

import { FullCalendarSchedulerComponent } from './full-calendar-scheduler/full-calendar-scheduler.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';

// Local pipes
import { DurationPipe } from './duration.pipe';
import { ZeroToTextPipePipe } from './zero-to-text-pipe.pipe';
import { FileSizePipe } from './file-size.pipe';
import { BreakifyPipe } from './breakify.pipe';

// Local misc stuff
import { ModalRouteHeaderComponent } from './modal-route-header/modal-route-header.component';
import { ModalDialogHeaderComponent } from './modal-dialog-header/modal-dialog-header.component';
import { ModalDialogFooterComponent } from './modal-dialog-footer/modal-dialog-footer.component';

// Define constants to bundle up ecah of the types of import.
const FLEX_LAYOUT_MODULES: any[] = [FlexLayoutModule];

const ANGULAR_MODULES: any[] = [FormsModule, ReactiveFormsModule];

const MATERIAL_MODULES: any[] = [
	MatAutocompleteModule,
	MatButtonModule,
	MatButtonToggleModule,
	MatCardModule,
	MatCheckboxModule,
	MatChipsModule,
	MatDatepickerModule,
	MatDialogModule,
	MatExpansionModule,
	MatIconModule,
	MatInputModule,
	MatListModule,
	MatMenuModule,
	MatNativeDateModule,
	MatProgressSpinnerModule,
	MatProgressBarModule,
	MatRadioModule,
	MatSelectModule,
	MatSidenavModule,
	MatSlideToggleModule,
	MatSnackBarModule,
	MatTabsModule,
	MatToolbarModule,
	MatTooltipModule,
];

const COVALENT_MODULES: any[] = [
	CovalentCommonModule,
	CovalentDialogsModule,
	CovalentFileModule,
	CovalentLayoutModule,
	CovalentLoadingModule,
	CovalentMessageModule,
];

const DIRECTIVES: any[] = [BlockNonNumberDirective, EqualValidatorDirective, FocusDirective];

const PIPES: any[] = [DurationPipe, ZeroToTextPipePipe, FileSizePipe, BreakifyPipe];

const CONTROLS: any[] = [
	ApplyCancelComponent,
	ButtonComponent,
	CheckboxComponent,
	DateComponent,
	DisplayComponent,
	HtmlEditorComponent,
	IconButtonComponent,
	RadioGroupComponent,
	SelectComponent,
	SelectionGridComponent,
	SwitchComponent,
	TextComponent,
	TextAreaComponent,
	TextPlainComponent,
	ButtonToggleComponent,

	FullCalendarSchedulerComponent,
	ModalRouteHeaderComponent,
	SearchFilterComponent,
	ModalDialogHeaderComponent,
	ModalDialogFooterComponent,

	CaseSearchItemComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ANGULAR_MODULES,
		MATERIAL_MODULES,
		COVALENT_MODULES,
		FLEX_LAYOUT_MODULES,

		AgGridModule.withComponents([]),
		TextMaskModule,
		SplitPaneModule,
		QuillModule,
		VirtualScrollModule,
	],
	declarations: [DIRECTIVES, CONTROLS, PIPES],
	exports: [
		ANGULAR_MODULES,
		MATERIAL_MODULES,
		COVALENT_MODULES,
		FLEX_LAYOUT_MODULES,

		AgGridModule,
		TextMaskModule,
		SplitPaneModule,
		QuillModule,
		VirtualScrollModule,

		DIRECTIVES,
		PIPES,
		CONTROLS,
	],
	providers: [PIPES, UnmanagedCookieService],
})
export class SharedModule {}
