import { Component, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
	IClientEditLoadDto,
	IClientEditDto,
	FormUtils
} from 'app/services';
import { IForm } from 'app/interfaces';

@Component({
	selector: 'pp-client-details-edit',
	templateUrl: './client-details-edit.component.html',
	styleUrls: ['./client-details-edit.component.scss']
})
export class ClientDetailsEditComponent implements OnChanges, IForm {
	private forceHasChanges = false;

	form: FormGroup;

	@Input() loadDto: IClientEditLoadDto;
	@Input() editDto: IClientEditDto;
	@Input() readOnly: boolean;

	constructor(
		private fb: FormBuilder
	) {
		this.buildForm();
	}

	ngOnChanges(hash) {
		if (hash.editDto) {
			this.form.reset();
			this.form.patchValue(this.editDto);
			this.configureValidation();

			this.markClean();
		}
	}

	addressesUpdated() {
		this.forceHasChanges = true;
	}

	getForm(): FormGroup {
		return this.form;
	}

	isValid(): boolean {
		return FormUtils.isFormValid(this);
	}

	hasChanges(): boolean {
		return this.form.dirty || this.forceHasChanges;
	}

	markClean() {
		this.forceHasChanges = false;
	}

	applyChanges(obj) {
		FormUtils.applyChanges(this.form, obj);
	}

	private buildForm() {
		this.form = this.fb.group({
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			description: ['', Validators.required]
		});
	}

	private configureValidation() {
		// Disable fields not being used to turn off their validation.
		if (this.editDto.isDescriptionOnly) {
			this.form.controls.firstName.disable();
			this.form.controls.lastName.disable();
		} else {
			this.form.controls.description.disable();
		}

		if (this.readOnly) {
			this.form.disable();
		}
	}
}
