import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import {
	ContractInfoDto
} from 'app/services';

@Component({
	selector: 'pp-contract-service-type-items-display',
	templateUrl: './contract-service-type-items-display.component.html',
	styleUrls: ['./contract-service-type-items-display.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractServiceTypeItemsDisplayComponent {
	@Input() contractInfo: ContractInfoDto;
}
