import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import {
	MatSnackBar,
} from '@angular/material';

import { LoggerService } from './logger.service';
import { ConfigService } from './config.service';
import { CheckSaveChangesService } from './check-save-changes.service';
import { LoadingStateService } from './loading-state.service';
import { CrudService } from './crud.service';
import { HttpErrorResponse } from '@angular/common/http';
import { WarningsService } from './warnings.service';
import { TdDialogService } from '@covalent/core';

const DEFAULT_SNACKBAR_DURATION = 2000;

@Injectable()
export class CommonService {
	constructor(
		public crud: CrudService,
		public http: HttpClient,
		public logger: LoggerService,
		public config: ConfigService,
		public location: Location,
		public loadingState: LoadingStateService,
		public snackBar: MatSnackBar,
		public checkSaveChanges: CheckSaveChangesService,
		public router: Router,
		public warnings: WarningsService,
		public dialog: TdDialogService,
	) { }

	showSnackBarMessage(snackMessage: string, duration: number = DEFAULT_SNACKBAR_DURATION) {
		this.snackBar.open(snackMessage, '', {
			duration
		});
	}

	isAlreadyHandledError(err: any): boolean {
		if (err instanceof HttpErrorResponse) {
			if (err.status === 401 || err.status === 503 || err.status === 504) {
				return true;
			}
		}
		return false;
	}

	get systemPreferences() {
		return this.config.systemPreferences;
	}
}
