import { Component, Output, EventEmitter } from '@angular/core';
import {
	StatisticContainerDto,
	StatisticHeaderEditDto,
} from 'app/services';
import { IEditComponent } from 'app/interfaces';

import { ClientEditBaseComponent } from '../client-edit-base.component';

@Component({
	selector: 'pp-client-edit-statistics',
	templateUrl: './client-edit-statistics.component.html',
	styleUrls: ['./client-edit-statistics.component.scss']
})
export class ClientEditStatisticsComponent extends ClientEditBaseComponent implements IEditComponent {
	private changes: boolean;

	@Output() changed = new EventEmitter<boolean>();

	constructor() {
		super();
	}

	markClean() {
		this.changes = false;
	}

	statisticsChanged(e) {
		this.changes = true;
		this.changed.emit(true);
	}

	hasChanges() {
		return this.changes;
	}
}
