import { Component, OnInit, Input } from '@angular/core';
import {
	StatisticHeaderEditDto,
	OrganisationDto,
} from 'app/services';

import { CasesService }  from 'app/cases/cases.service';
import { IEditComponent } from 'app/interfaces';

@Component({
	selector: 'pp-header-edit-summary',
	templateUrl: './header-edit-summary.component.html',
	styleUrls: ['./header-edit-summary.component.scss']
})
export class HeaderEditSummaryComponent implements OnInit, IEditComponent {
	private changes: boolean;
	organisation: OrganisationDto;

	@Input() header: StatisticHeaderEditDto;

	constructor(
		private service: CasesService,
	) { }

	hasChanges() {
		return this.changes;
	}

	markClean() {
		this.changes = false;
	}

	ngOnInit() {
		this.organisation = this.service.currentLoadDto.organisation;
	}

	organisationChanged(e) {
		this.changes = true;
	}
}
