export class ExtendedDetails {

  redFlagColour:string;
  createDateTime:Date;
  medicationsTaken:string;
  disabilities:string;
	extendedDetailsItems: ExtendedDetailsItemsDisplay[]; 
  hasUsedServiceBefore:boolean;
  usedProfessional:string;
  usedService:string;
  usedServiceMonth:Date;
  usedServiceMonthFormatted:string;
}

export class ExtendedDetailsItemsDisplay
{
  text: string;
	value: boolean;
  displayImage:boolean;
  colour:string;
}

export class ExtendedDetailsHelper
{
  public static redFlagAlternativeAppearenceColours = ['red','blue','green','orange','purple','yellow'];
}

