import { Injectable, Component, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { CanDeactivate } from '@angular/router';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { TdDialogService } from '@covalent/core';
import * as _ from 'lodash';

import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { FormUtils, UpdaterOptions } from './form-utils';
import { SaveResult, SaveResultDto } from 'app/services/server-data';
import { ICheckSaveChanges, IForm, IEditComponent, respondsTo } from 'app/interfaces';
import { WarningsService } from './warnings.service';

const SAVED_SNACKBAR_DURATION = 3000;
const SAVE_FAILED_SNACKBAR_DURATION = 6000;

@Injectable()
export class CheckSaveChangesService implements CanDeactivate<ICheckSaveChanges> {
	public inProgress$ = new BehaviorSubject(false);

	constructor(
		private logger: LoggerService,
		private dialog: MatDialog,
		private dialogService: TdDialogService,
		private location: Location,
		private snackBar: MatSnackBar,
		private modal: ModalService,
		private warningsService: WarningsService,
		private readonly router: Router
	) {}

	canDeactivate(
		component: ICheckSaveChanges,
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
		nextState: RouterStateSnapshot
	): Observable<boolean> | boolean {
		if (this.modal.isOpen) {
			return false;
		}

		// Ignore if state and nextState are the same URL minus optional parameters.
		// This is used for appointments and availability in practice mode where each
		// professional/office has the same route but with optional parameters used to
		// control the tabs.
		const routeUrlRegex = /(^\/.*);/;
		const currentStateUrl = routeUrlRegex.exec(state.url);
		const nextStateUrl = routeUrlRegex.exec(nextState.url);

		if (currentStateUrl && nextStateUrl && currentStateUrl[1] === nextStateUrl[1]) {
			return true;
		}

		if (!this.componentHasChanges(component)) {
			return true;
		}

		const canDeactivateResult = new Subject<boolean>();
		const objectTypeName = this.getObjectName(component);

		// Ask the user to save, discard or cancel.
		let dialogRef = this.dialog.open(CheckSaveChangesDialogComponent, {
			data: {
				objectType: objectTypeName,
			},
		});
		dialogRef.afterClosed().subscribe((result: string) => {
			switch (result) {
				case 'Save':
					const obs = this.saveChanges(component);
					if (typeof obs === 'boolean') {
						canDeactivateResult.next(false);
						return;
					}
					obs.subscribe((saveResult) => {
						const savedOK = saveResult.saveResult === SaveResult.OK;
						canDeactivateResult.next(savedOK);
					});
					break;
				case 'Discard':
					if (respondsTo(component, 'discardChanges')) {
						component.discardChanges();
					}
					canDeactivateResult.next(true);
					break;
				case 'Cancel':
					// Workaround for Angular bug - see: https://github.com/angular/angular/issues/13586#issuecomment-402250031
					const currentUrlTree = this.router.createUrlTree([], route);
					const currentUrl = currentUrlTree.toString();
					// Use replaceState so as to not push onto the browser history.
					this.location.replaceState(currentUrl);

					canDeactivateResult.next(false);
					break;
				default:
					break;
			}
		});

		return canDeactivateResult;
	}

	close() {
		this.location.back();
	}

	/**
	 * Hierarchically saves changes to a component.
	 * @param {((IEditComponent | ICheckSaveChanges))} component The root component.
	 * @returns {Observable<T>} An Observable<T> that produces the save result.
	 * @memberof CheckSaveChangesService
	 */
	saveChanges(
		component: IEditComponent | ICheckSaveChanges,
		confirmWarning?: boolean
	): Observable<SaveResultDto<any>> | boolean {
		const objectTypeName = this.getObjectName(component);
		const showSaveError = () => {
			const snackMessage = `Save of ${objectTypeName} failed.`;
			this.snackBar.open(snackMessage, '', {
				duration: SAVE_FAILED_SNACKBAR_DURATION,
			});
		};

		const obs = this.componentSaveChanges(component, confirmWarning);
		if (typeof obs === 'boolean') {
			// componentSaveChanges returned a boolean so we've aborted the save.
			// e.g. there are validation errors.
			return false;
		}

		// Signal save in progress.
		this.signalInProgress(true);

		const showsProgress = respondsTo(component, 'showsProgress') ? component.showsProgress() : false;

		// We're going to save so show a dialog while the save is in progress.
		let dialogRef = this.dialog.open(SaveProgressDialogComponent, {
			data: {
				objectType: objectTypeName,
				hasProgress: showsProgress,
				progress: showsProgress ? component.progress() : null,
			},
			disableClose: true,
		});

		// Create the observable to return that the caller can subscribe to for the save result.
		const saveChangesResult = new Subject<SaveResultDto<any>>();

		// Subscribe to the save observable - this will result in the save HTTP operation actually happening.
		obs.subscribe(
			(result) => {
				// Got back a result from the server - check the status.
				dialogRef.close();

				this.signalInProgress(false);

				switch (result.saveResult) {
					case SaveResult.Error:
						showSaveError();
						break;
					case SaveResult.Warnings:
						this.warningsService
							.showWarningsWithConfirm('Warning', result.warnings, result.confirms)
							.subscribe((confirm: boolean | string) => {
								if (confirm === true) {
									// User confirmed warning - re-initiate the save, this time with the warning confirmed.
									return this.saveChanges(component, true);
								}
							});
						break;
					case SaveResult.OK:
						const snackMessage = _.startCase(`${objectTypeName} saved`);
						this.snackBar.open(snackMessage, '', {
							duration: SAVED_SNACKBAR_DURATION,
						});

						// Saved successfully. Mark forms as clean.
						const forms = this.getForms(component);
						_.forEach(forms, (f) => f.markAsPristine());

						this.componentsMarkClean(component);

						const formsWithChanges = _.filter(forms, (f) => FormUtils.hasChanges(f));
						if (formsWithChanges.length) {
							this.logger.logWarning('These forms still have changes!', formsWithChanges);
							throw new Error('There are forms with changes after successful save.');
						}
						break;
					default:
						throw new Error('Unexpected SaveResult value');
				}

				// Emit the save result (whatever it was) onto the result observable.
				saveChangesResult.next(result);
			},
			(err) => {
				// Something bad happened (e.g. exception on server, etc.)
				dialogRef.close();
				showSaveError();
				this.signalInProgress(false);
			},
			() => {
				this.signalInProgress(false);
			}
		);

		return saveChangesResult;
	}

	reset() {
		this.signalInProgress(false);
	}

	private signalInProgress(value) {
		this.inProgress$.next(value);
	}

	private getObjectName(component: IEditComponent | ICheckSaveChanges): string {
		let objectName = 'object';
		if (respondsTo(component, 'getObjectName')) {
			objectName = (component as ICheckSaveChanges).getObjectName();
		}

		return objectName;
	}

	private getForms(
		component: IEditComponent | ICheckSaveChanges,
		components: (IEditComponent | IForm)[] = undefined
	): FormGroup[] {
		if (!components) {
			components = [];
			this.getComponents(component, components);
		}

		const forms: FormGroup[] = [];
		if (respondsTo(component, 'getForm')) {
			forms.push((component as IForm).getForm());
		}
		if (components) {
			_.forEach(components, (c) => {
				if (respondsTo(c, 'getForm')) {
					let form = (c as IForm).getForm();
					if (!form) {
						return;
					}
					forms.push(form);
				}
			});
		}

		return forms;
	}

	private componentsMarkClean(component: IEditComponent, cleaned: IEditComponent[] = undefined) {
		if (!cleaned) {
			cleaned = [];
		}

		if (respondsTo(component, 'markClean')) {
			component.markClean();
		}

		let components = [];
		this.getComponents(component, components);

		if (components && components.length) {
			components.forEach((c) => {
				if (cleaned.indexOf(c) === -1) {
					cleaned.push(c);
					this.componentsMarkClean(c, cleaned);
				}
			});
		}
	}

	/**
	 * Performs a hierarchical save using a root component.
	 * @param {((IEditComponent | ICheckSaveChanges))} component The root component.
	 * @returns {Observable<T>} An Observable that produces the result of the save.
	 * @memberof CheckSaveChangesService
	 */
	private componentSaveChanges(
		component: IEditComponent | ICheckSaveChanges,
		confirmWarning?: boolean
	): Observable<SaveResultDto<any>> | boolean {
		let components = [];
		this.getComponents(component, components);

		if (components && components.length === 1) {
			component = components[0];
		}

		// Get the object to update
		let obj: object;
		if (respondsTo(component, 'getObject')) {
			obj = component.getObject();
		}

		// Get the forms to update the object, if all valid.
		const forms = this.getForms(component, components);

		// Function for calling the isValid function on IEditComponent instances, if it's implemented.
		const callIsValid = (c: IEditComponent) => {
			if (respondsTo(c, 'isValid')) {
				return c.isValid();
			}
			return true;
		};

		// Call the isValid method on each component that implements it.
		const allIsValid = _.every(components, (c) => callIsValid(c));

		// Check that all forms are valid.
		const allValid = _.every(forms, (f) => FormUtils.isFormValid(f));
		if (!allValid || !allIsValid) {
			// let formsInError = forms.filter(f => f.invalid);
			// let controlsInError = [];
			// forms.forEach(f => {
			// 	for (let c in f.controls) {
			// 		let control = f.controls[c];
			// 		if (control.invalid) {
			// 			controlsInError.push(control);
			// 		}
			// 	}
			// });

			// console.log(controlsInError);

			this.dialogService.openAlert({
				message: "Can't save - there are errors that must be corrected.",
				title: 'Save changes',
			});
			return false;
		}

		// Get each form to apply its update.
		_.forEach(forms, (f) => FormUtils.applyChanges(f, obj, UpdaterOptions.AS_NEW));

		// Find the component that will save changes - either the primary component that was supplied or
		// the first child.
		let changeSaver: ICheckSaveChanges | IEditComponent;
		if (respondsTo(component, 'saveChanges')) {
			changeSaver = component;
		} else if (components.length > 0 && respondsTo(components[0], 'saveChanges')) {
			changeSaver = components[0];
		}

		if (!changeSaver) {
			throw new Error("Can't save - nothing implements saveChanges()");
		}

		// Finally, call saveChanges with the updated object.
		return changeSaver.saveChanges(obj, confirmWarning);
	}

	private componentHasChanges(component: IEditComponent | ICheckSaveChanges) {
		// If the component implements getComponents, check whether any of the components have changes.
		if (respondsTo(component, 'getComponents')) {
			let components = _.filter((component as ICheckSaveChanges).getComponents(), (c) => c && c !== component);

			// Check whether any of the components has changes (recursing down).
			if (
				_.some(components, (c) => {
					let result = this.componentHasChanges(c);
					return result;
				})
			) {
				return true;
			}
		}

		if (respondsTo(component, 'hasChanges')) {
			if (component.hasChanges()) {
				return true;
			}
		}

		if (respondsTo(component, 'getForm')) {
			if (FormUtils.hasChanges(component as IForm)) {
				return true;
			}
		}

		return false;
	}

	private getComponents(component: IEditComponent | ICheckSaveChanges, foundComps: (IEditComponent | IForm)[]) {
		if (respondsTo(component, 'getComponents')) {
			// getComponents can return nulls, so filter those out.
			const comps = (component as ICheckSaveChanges).getComponents().filter((c) => !!c);
			_.forEach(comps, (c) => {
				if (foundComps.includes(c)) {
					return;
				}
				foundComps.push(c);

				// Recurse down
				this.getComponents(c, foundComps);
			});
		}
	}
}

@Component({
	template: `
		<h1 matDialogTitle>Save changes?</h1>
		<div mat-dialog-content>This {{ data.objectType }} has unsaved changes.</div>
		<div
			mat-dialog-actions
			fxLayout="row"
			ngClass.lt-md="dialog-actions-small"
			fxLayoutGap.lt-md="0"
			fxLayoutGap="10px"
		>
			<span fxFlex></span>
			<button mat-button matDialogClose="Cancel">Cancel</button>
			<button mat-button color="warn" matDialogClose="Discard">Discard</button>
			<button mat-button color="primary" matDialogClose="Save">Save</button>
			<span fxHide.gt-sm="true" fxFlex></span>
		</div>
	`,
})
export class CheckSaveChangesDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<CheckSaveChangesDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}
}

@Component({
	template: `
		<h1 matDialogTitle>Saving...</h1>
		<div mat-dialog-content>
			<div *ngIf="data.hasProgress" fxLayout="row" fxLayoutAlign="center center">
				<mat-progress-bar mode="determinate" value="{{ data.progress | async }}"> </mat-progress-bar>
			</div>
			<div>This {{ data.objectType }} is being saved.</div>
		</div>
	`,
})
export class SaveProgressDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<SaveProgressDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}
}
