import { ConfirmDialogService } from './../services/confirm-dialog.service';
import { CustomisationService } from './../services/customisation.service';
import { Component, ElementRef, ViewChild, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ConfigService } from 'app/services';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MediaObserver } from '@angular/flex-layout';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatIconRegistry } from '@angular/material';

import { SearchComponent } from '../search/search.component';
import { LogoutService, AuthenticationService } from 'app/services';
import { environment } from 'environments/environment';

class RouteSpec {
	title: string;
	route: string;
	icon: string;
	hideForPractice?: boolean;
	hideForProfessional?: boolean;
	hideForEmployee?: boolean;
	hideResouceHub?: boolean;
}

interface IToolbarStyle {
	'background-color'?: string;
	color?: string;
}

@Component({
	selector: 'pp-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
	@ViewChild('search', { static: true }) searchEl: ElementRef;

	showCopyright = false;
	username: string;
	version: string;
	serverVersion: string;

	practiceMode = false;
	isEmployee = false;
	useResouceHub = false;

	// Routes for the sidebar menu.
	allRoutes: RouteSpec[] = [
		{
			title: 'Dashboard',
			route: '/dashboard',
			icon: 'dashboard'
		},
		{
			title: 'Cases',
			route: '/cases',
			icon: 'view_quilt'
		},
		{
			title: 'Appointments',
			route: '/appointments',
			icon: 'face',
			hideForPractice: true
		},
		{
			title: 'Invoices',
			route: '/invoices',
			icon: 'receipt',
			hideForEmployee: true
		},
		{
			title: 'Availability',
			route: '/availability',
			icon: 'people',
			hideForPractice: true
		},
		{
			title: 'Activity query',
			route: '/activity-query',
			icon: 'reorder'
		},
		{
			title: 'Resource Hub',
			route: '/resource-hub',
			icon: 'library_books',
			hideResouceHub: true
		}
	];

	routes: RouteSpec[];

	sidenavMode = 'side';
	sidenavOpened = true;

	mediaWatcher: Subscription;
	activeMediaQuery = '';
	mediaAlias = '';

	title = 'Professional Portal';
	toolbarStyle: IToolbarStyle = {};

	constructor(
		private config: ConfigService,
		private dialog: MatDialog,
		private logoutService: LogoutService,
		public auth: AuthenticationService,
		public media: MediaObserver,
		public iconRegistry: MatIconRegistry,
		sanitizer: DomSanitizer,
		customisationService: CustomisationService,
		private confirmDialog: ConfirmDialogService
	) {
		this.version = environment.PACKAGE.version;
		this.serverVersion = this.getServerVersion();
		this.username = this.getUsername();

		iconRegistry.addSvgIcon(
			'eapexec-icon',
			sanitizer.bypassSecurityTrustResourceUrl('assets/icons/eap-exec-icon.svg')
		);

		this.practiceMode = config.config.isPractice;
		this.isEmployee = config.config.isEmployee;
		this.useResouceHub = config.systemPreferences.useResouceHub;

		this.configureRoutesForModes();

		const customisation = customisationService.customisation;
		if (customisation.toolbarBackgroundColor) {
			this.toolbarStyle['background-color'] = customisation.toolbarBackgroundColor;
		}

		if (customisation.toolbarColor) {
			this.toolbarStyle.color = customisation.toolbarColor;
		}

		this.title = customisation.appTitle || this.title;
		this.showCopyright = !!customisation.showCopyright;
	}

	ngOnInit() {
		this.auth.isAuthenticated$.subscribe(isAuthenticated => {
			if (isAuthenticated) {
				this.isEmployee = this.config.config.isEmployee;
				this.configureRoutesForModes();
			}
		});

		this.auth.isPracticeMode$.subscribe(practiceMode => {
			this.practiceMode = practiceMode;
			this.configureRoutesForModes();
		});

		this.mediaWatcher = this.media.asObservable().subscribe(changes => {
			let change = changes[0];

			this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';
			this.mediaAlias = change.mqAlias;
			this.configureFromMediaAlias();
		});

		this.sidenavOpened = this.media.isActive('gt-sm');
	}

	ngOnDestroy() {
		this.mediaWatcher.unsubscribe();
	}

	toggleSidenav() {
		this.sidenavOpened = !this.sidenavOpened;
	}

	sidenavOpenedChanged(isOpen) {
		this.sidenavOpened = isOpen;
	}

	configureFromMediaAlias() {
		this.sidenavMode = this.media.isActive('lt-md') ? 'over' : 'side';
	}

	configureRoutesForModes() {
		this.routes = this.allRoutes.filter(r => {
			if (r.hideForEmployee && this.isEmployee) {
				return false;
			}

			if (r.hideForPractice && this.practiceMode) {
				return false;
			}

			if (r.hideForProfessional && !this.practiceMode) {
				return false;
			}

			if (r.hideResouceHub && !this.useResouceHub) {
				return false;
			}

			return true;
		});
	}

	async logout(requireConfirm?: boolean) {
		const doLogout = () => {
			this.logoutService.forceLogin();
		};

		if (!requireConfirm) {
			doLogout();
			return;
		}

		const confirmResult = await this.confirmDialog.confirm('Are you sure you want to log out?', 'Confirm Logout');
		if (confirmResult) {
			doLogout();
		}
	}

	testError() {
		throw new Error('Test error');
	}

	showSearch() {
		const gtSm = this.media.isActive('gt-sm');
		const rect = this.searchEl.nativeElement.getBoundingClientRect();

		let width;
		let height;
		let left;
		if (gtSm) {
			width = 870;
			height = '80%';
			left = rect.right - width + 'px';
		} else {
			width = 400;
			height = '90%';
			left = '10px';
		}
		let dialogRef = this.dialog.open(SearchComponent, {
			width: width + 'px',
			height: height,
			panelClass: 'search-dialog',
			position: {
				left,
				top: '60px'
			}
		});
	}

	private getUsername() {
		const userConfig = this.config.config;
		if (!userConfig) {
			return '';
		}
		return userConfig.name;
	}

	private getServerVersion() {
		const systemPrefs = this.config.systemPreferences;
		if (!systemPrefs) {
			return '';
		}
		return systemPrefs.serverVersion;
	}
}
