import { Component, AfterViewInit, ViewChild } from '@angular/core';
import {
	MatDialogRef,
} from '@angular/material';

import { CaseSearchComponent } from './case-search/case-search.component';
import { InvoiceSearchComponent } from './invoice-search/invoice-search.component';
import {
	SearchStateService, ConfigService
} from 'app/services';

const TAB_CASES = 'Cases';
const TAB_INVOICES = 'Invoices';

@Component({
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent implements AfterViewInit {
	private selectedTab: string = TAB_CASES;
	isEmployee: boolean;

	@ViewChild('cases', { static: true }) cases: CaseSearchComponent;
	@ViewChild('invoices', { static: true }) invoices: InvoiceSearchComponent;

	constructor(
		public dialogRef: MatDialogRef<SearchComponent>,
		private searchState: SearchStateService,
		private config: ConfigService,
	) {
		this.isEmployee = config.config.isEmployee;

		if (searchState.lastState) {
			this.selectedTab = searchState.lastState;

			if (this.isEmployee && this.selectedTab === TAB_INVOICES) {
				this.selectedTab = TAB_CASES;
			}
		}
	}

	ngAfterViewInit() {
		this.setFocus();
	}

	resultSelected() {
		this.dialogRef.close();
	}

	selectCases() {
		this.selectedTab = TAB_CASES;
		this.searchState.lastState = this.selectedTab;
		this.setFocus();
	}

	selectInvoices() {
		this.selectedTab = TAB_INVOICES;
		this.searchState.lastState = this.selectedTab;
		this.setFocus();
	}

	setFocus() {
		setTimeout(() => {
			if (this.isCases) {
				this.cases.setFocus();
			} else {
				this.invoices.setFocus();
			}
		}, 500);
	}

	get isInvoices() {
		return this.selectedTab === TAB_INVOICES;
	}

	get isCases() {
		return this.selectedTab === TAB_CASES;
	}
}
