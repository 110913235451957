import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ConfigService, Guid } from 'app/services';

import {
	AdditionalSessionDto,
	TriState
} from 'app/services';

@Injectable()
export class ContractTermsService {
	constructor(
		private config: ConfigService
	) {}

	newAdditionalSession(): AdditionalSessionDto {
		let professionals = this.config.professionals;
		let professionalId = undefined;
		if (professionals.length === 1) {
			professionalId = professionals[0].id;
		}

		const newRequest = {
			uniqueId: Guid.newGuid(),
			requestId: 0,
			requestedByBusinessRoleId: professionalId,
			dateOfRequest: moment().startOf('day').toDate(),
			primaryConcernAndReason: '',
			issueResolutionAndGoal: '',
			notes: '',
			requestedNumberPerClient: 1,
			isClientWorking: TriState.Unknown,
			isRehabilitationCase: TriState.Unknown,
			isNewIssue: TriState.Unknown,
			isWorkRelatedIssue: TriState.Unknown,
			isClientConsentToOrgDiscussion: TriState.Unknown,
			isClientConsentToReleaseName: TriState.Unknown,
			isSupported: TriState.Unknown,
			isClientUsingOtherExternalProfessional: TriState.Unknown,
			isHelpRequiredDevelopingClientSupportPlan: TriState.Unknown,
			isHROrManagerRecommended: TriState.Unknown,
			isLegalIssue: TriState.Unknown,
			supportedByRole: '',
			supportedByName: '',
		};

		return newRequest as AdditionalSessionDto;
	}
}
