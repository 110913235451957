import { Component, OnChanges, Input, forwardRef, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { BaseComponent } from './base-component';

let identifier = 0;

@Component({
	selector: 'pp-textarea',
	template: `
<div class="form-component">
	<div fxLayout="row">
		<mat-form-field fxFlex>
			<textarea
				matInput
				[rows]="rows"
				#inputElement
				[id]="identifier"
				[required]="required"
				[placeholder]="placeholder"
				[maxlength]="maxLength"
				[disabled]="isDisabled"
				[(ngModel)]="value"
				[ngClass]="{ 'invalid': !isValid }"
				(blur)="onBlur()"></textarea>
		</mat-form-field>
	</div>
	<div class="validation-message" *ngIf="control?.touched && control.hasError('required')">{{ label }} is required</div>
</div>`,
	styleUrls: ['./text-area.component.scss'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextAreaComponent), multi: true },
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => TextAreaComponent), multi: true }
	]
})
export class TextAreaComponent extends BaseComponent<string> implements OnChanges, OnInit {
	@Input() label: string;
	@Input() maxLength = 512 * 1024; // 512K
	@Input() rows = 3;
	@Input() placeholder = '';

	public identifier = `text-area-${identifier++}`;

	@ViewChild('inputElement', { static: true }) inputEl: ElementRef;

	onBlur() {
		this.touch();
	}

	ngOnInit() {
		if (!this.placeholder) {
			this.placeholder = this.label;
		}
	}

	ngOnChanges(inputs) {
		if (!inputs.placeholder) {
			this.placeholder = this.label || '';
		}
	}

	setFocus() {
		this.inputEl.nativeElement.focus();
		this.inputEl.nativeElement.select();
	}
}
