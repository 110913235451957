import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { StatisticContainerDto, StatisticDto, StatisticalTypeDto, StatisticalChoiceDto } from 'app/services';
import { MatCheckboxChange } from '@angular/material';

@Component({
	selector: 'pp-container-individual-grid',
	templateUrl: './container-individual-grid.component.html',
	styleUrls: ['./container-individual-grid.component.scss']
})
export class ContainerIndividualGridComponent implements OnInit {
	types: StatisticalTypeDto[];

	@Input() container: StatisticContainerDto;
	@Output() changed = new EventEmitter<StatisticDto>();

	ngOnInit() {
		this.types = this.container.statisticalGroup.statisticalTypes;
	}

	isChecked(choice: StatisticalChoiceDto): boolean {
		let stats = this.container.statistics;
		let statistic = stats.find(s => s.statisticalChoiceId === choice.id);
		return !!statistic;
	}

	update(type: StatisticalTypeDto, choice: StatisticalChoiceDto, e: MatCheckboxChange) {
		let stats = this.container.statistics;
		let statistic = stats.find(s => s.statisticalChoiceId === choice.id);
		let toEmit = null;
		if (e.checked) {
			if (statistic) {
				return;
			}

			// If not multi-choice, remove other options first.
			if (!this.container.statisticalGroup.isMultiChoice) {
				const existingForType = stats.find(s => s.statisticalTypeId === type.id);
				if (existingForType) {
					const index = stats.indexOf(existingForType);
					stats.splice(index, 1);
				}
			}

			let emptyStatistic = stats.find(s => s.isEmpty);
			if (emptyStatistic) {
				let index = stats.findIndex(s => s === emptyStatistic);
				stats.splice(index, 1);
			}

			statistic = <StatisticDto>{
				statisticalChoiceId: choice.id,
				statisticalTypeId: choice.statisticalTypeId
			};
			stats.push(statistic);
			toEmit = statistic;
		} else {
			if (!statistic) {
				return;
			}

			let index = stats.findIndex(s => s.statisticalChoiceId === choice.id);
			stats.splice(index, 1);
			toEmit = null;
		}

		this.changed.emit(toEmit);
	}
}
