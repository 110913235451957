import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'pp-completion-percent',
	templateUrl: './completion-percent.component.html',
	styleUrls: ['./completion-percent.component.scss']
})
export class CompletionPercentComponent implements OnInit {
	@Input() count: number;
	@Input() total: number;
	percent: number | string;
	isPercent: boolean;

	ngOnInit() {
		if (this.total) {
			this.percent = ((this.count * 100) / this.total).toFixed();
			this.isPercent = true;
		} else {
			this.percent = '-';
		}
	}
}
