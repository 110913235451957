import { ErrorHandler, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import * as StackTrace from 'stacktrace-js';
import { Injector } from '@angular/core';
import { environment } from '../../environments/environment';

import {
	ClientErrorDto,
	UserConfigDto,
	BrowserEnvironmentDto,
} from 'app/services';

const CHECK_INTERVAL: number = 10 * 60 * 1000; // 10 minutes

@Injectable()
export class CustomErrorHandler extends ErrorHandler {
	http: HttpClient;
	constructor(injector: Injector) {
		// We rethrow exceptions, so operations like 'bootstrap' will result in an error
		// when an error happens. If we do not rethrow, bootstrap will always succeed.
		super();
		setTimeout(() => {
			this.http = injector.get(HttpClient);
		});

		// Periodically check for errors recorded in localStorage that didn't successfully send.
		setInterval(this.checkErrors.bind(this), CHECK_INTERVAL);
	}

	checkErrors() {
		try {
			const keys = Object.keys(localStorage);
			const storageKey = keys.find(k => k.startsWith('client-error'));
			if (!storageKey) {
				return;
			}

			const body = localStorage.getItem(storageKey);

			const pendingId = `pending-${storageKey}`;
			localStorage.setItem(pendingId, body);
			localStorage.removeItem(storageKey);

			this.http.post('api/errors', body, {
				responseType: 'text'
			}).subscribe(logResult => {
				localStorage.removeItem(pendingId);
			}, err => {
				localStorage.removeItem(pendingId);
				localStorage.setItem(storageKey, body);
			});
		} catch (err) {
			console.error('Ignoring error while checking for errors', err);
		}
	}

	handleError(error) {
		if (!environment.production) {
			super.handleError(error);
			return;
		}

		alert('Oops... an unexpected error has occurred.\r\nThis has been logged and will be investigated.');

		const browserEnv: BrowserEnvironmentDto = {
			userAgent: window.navigator.userAgent,
			screenHeight: window.screen.height,
			screenWidth: window.screen.width,
			innerHeight: window.innerHeight,
			innerWidth: window.innerWidth,
			colorDepth: window.screen.colorDepth
		};

		const userConfigStr = sessionStorage.getItem('userConfig');
		let username = '';
		if (userConfigStr) {
			const userConfig = JSON.parse(userConfigStr) as UserConfigDto;
			username = userConfig.name;
		}

		const dto: ClientErrorDto = {
			dateTime: new Date(),
			username,
			clientVersion: environment.PACKAGE.version,
			environment: browserEnv,
			stackTraceError: false,
			message: error.message || error,
			url: window.location.toString(),
			stackTrace: ''
		};

		// Decode the stacktrace.
		StackTrace.fromError(error).then(r => {
			dto.stackTrace = JSON.stringify(r);
			const body = JSON.stringify(dto);

			// Record in localStorage as pending in case it doesn't successfully send.
			const storageId = `client-error-${dto.dateTime.toISOString()}`;
			const pendingId = `pending-${storageId}`;
			localStorage.setItem(pendingId, body);

			// Try sending back to the server.
			this.http.post('api/errors', body, {
				responseType: 'text'
			}).subscribe(logResult => {
				// Successfully sent, remove pending item.
				localStorage.removeItem(pendingId);
			}, err => {
				// Failed to send - remove pending and replace with actual item
				// to be retried on the interval above.
				localStorage.removeItem(pendingId);
				localStorage.setItem(storageId, body);
			});
		}).catch(reason => {
			// Something went wrong decoding the stacktrace. Put it in localStorage to be picked up
			// and sent by the interval above.
			const now = new Date();
			const storageId = `client-error-${now.toISOString()}`;
			dto.stackTraceError = true;
			localStorage.setItem(storageId, JSON.stringify(dto));
		});

		super.handleError(error);
	}
}