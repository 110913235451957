import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'duration'
})
export class DurationPipe implements PipeTransform {
	transform(value: number, args?: any): any {
		let formattedDuration: string;
		if (value < 1) {
			formattedDuration = (value * 60).toString() + ' minutes';
		} else if (Math.floor(value) === value) {
			formattedDuration = value.toString() + ` hour${value > 1 ? 's' : ''}`;
		} else {
			formattedDuration = value.toFixed(2) + ' hours';
		}

		return formattedDuration;
	}
}
