import { Injectable } from '@angular/core';
import { AuthorisationsDto } from 'app/services/server-data';
import { ConfigService } from './config.service';

@Injectable()
export class AuthsService {
	private _emptySettings = <AuthorisationsDto> {};

	constructor(private config: ConfigService) {
	}

	get settings(): AuthorisationsDto {
		let userConfig = this.config.config;
		if (userConfig) {
			return userConfig.authorisations;
		}
		return this._emptySettings;
	}
}
