
import {switchMap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable ,  Subscription } from 'rxjs';

import {
	GlobalSearchResultItemDto
} from 'app/services';

import { SearchFilterComponent } from 'app/shared/search-filter/search-filter.component';
import { TextComponent } from 'app/shared/forms/text.component';
import { InvoicesSearchService } from './invoices-search.service';

@Component({
	selector: 'pp-invoice-search',
	templateUrl: './invoice-search.component.html',
	styleUrls: ['./invoice-search.component.scss']
})
export class InvoiceSearchComponent implements OnInit, OnDestroy {
	private subSearch: Subscription;

	// Template properties
	form: FormGroup;
	items: Observable<GlobalSearchResultItemDto[]>;

	@ViewChild('filter', { static: true }) filter: SearchFilterComponent;
	@ViewChild('search', { static: true }) search: TextComponent;

	@Output() resultSelected = new EventEmitter<void>();

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private decimalPipe: DecimalPipe,
		public searchService: InvoicesSearchService,
	) {
		this.buildForm();
	}

	ngOnInit() {
		const svc = this.searchService;
		this.form.patchValue({
			searchText: svc.searchText,
			dateFrom: svc.dateFrom,
			dateTo: svc.dateTo,
			openOnly: svc.activeOnly,
			showAll: svc.showAll
		});

		this.subSearch = this.form.valueChanges.pipe(
						debounceTime(300),
						distinctUntilChanged(),
						switchMap(v => {
							return svc.search(v.searchText, v.showAll, v.dateFrom, v.dateTo, v.openOnly, true);
						}),).subscribe();
	}

	ngOnDestroy() {
		if (this.subSearch) {
			this.subSearch.unsubscribe();
		}
	}

	onSelect(item: GlobalSearchResultItemDto) {
		const inInvoice = /\/invoices\/[0-9]+/.test(this.router.url);
		let additional;
		if (inInvoice) {
			additional = { replaceUrl: true };
		}

		this.searchService.setSearching(true);
		this.router.navigate(['/invoices', item.id], additional)
			.then(() => {
				this.resultSelected.emit();
				this.searchService.setSearching(false);
			})
			.catch(() => this.searchService.setSearching(false));
	}

	setFocus() {
		this.search.setFocus();
	}

	formatAmount(amount: number) {
		return this.decimalPipe.transform(amount, '1.2');
	}

	private buildForm() {
		this.form = this.fb.group({
			searchText: undefined,
			dateFrom: undefined,
			dateTo: undefined,
			openOnly: undefined,
			showAll: undefined
		});
	}
}
