import { Input, Output, EventEmitter } from '@angular/core';
import {
	StatisticalTypeDto,
	StatisticDto,
} from 'app/services';

export abstract class StatisticEditorBase {
	@Input() type: StatisticalTypeDto;
	@Input() statistic: StatisticDto;

	@Output() changed = new EventEmitter<StatisticDto>();

	protected emitChange() {
		this.changed.emit(this.statistic);
	}
}
