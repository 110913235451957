import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {
	StatisticHeaderEditDto,
} from 'app/services';

import { IEditComponent } from 'app/interfaces';

@Component({
	selector: 'pp-header-edit-statistics',
	templateUrl: './header-edit-statistics.component.html',
	styleUrls: ['./header-edit-statistics.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderEditStatisticsComponent implements IEditComponent {
	private changes: boolean;

	@Input() header: StatisticHeaderEditDto;

	hasChanges() {
		return this.changes;
	}

	markClean() {
		this.changes = false;
	}

	statisticsChanged() {
		this.changes = true;
	}
}
