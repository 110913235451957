import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseEditComponent } from 'app/shared/base-edit/base-edit.component';
import {
	CommonService,
	StatisticHeaderEditDto
} from 'app/services';
import { ICheckSaveChanges } from 'app/interfaces';
import { CasesService } from 'app/cases/cases.service';

import { HeaderEditSummaryComponent } from './header-edit-summary/header-edit-summary.component';
import { HeaderEditStatisticsComponent } from './header-edit-statistics/header-edit-statistics.component';

@Component({
	selector: 'pp-header-edit',
	templateUrl: './header-edit.component.html',
	styleUrls: ['./header-edit.component.scss'],
})
export class HeaderEditComponent
	extends BaseEditComponent<StatisticHeaderEditDto>
	implements OnInit, ICheckSaveChanges {
	private activityHeaderId: number;

	@ViewChild('summary', { static: false }) summary: HeaderEditSummaryComponent;
	@ViewChild('statistics', { static: false }) statistics: HeaderEditStatisticsComponent;

	constructor(
		common: CommonService,
		private router: Router,
		private route: ActivatedRoute,
		private service: CasesService,
	) {
		super(common);

		this.objectName = 'header';
		this.activityHeaderId = +route.parent.snapshot.paramMap.get('id');
	}

	ngOnInit() {
		let id = this.route.snapshot.paramMap.get('id');
		this.fetch(() => this.service.getStatisticHeader(this.activityHeaderId, +id));
	}

	getComponents() {
		return [
			this,	// Needed to get the object to save against.
			this.summary,
			this.statistics
		];
	}

	saveChanges() {
		return this.service.statisticHeaderSave(this.activityHeaderId, this.editDto);
	}

	complete(result: boolean) {
		this.completeWithBack(result, undefined, this);
		// this.completeWithBack(result,
		// 	() => this.service.statisticHeaderSave(this.activityHeaderId, this.editDto));
	}
}
