import { Component, Input, Output, forwardRef, OnChanges, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { BaseComponent } from './base-component';

let identifier = 0;

@Component({
	selector: 'pp-html-editor',
	template: `
<div class="form-component">
	<quill-editor
		[readOnly]="isReadOnly"
		(onContentChanged)="handleContentChanged($event)"
		[(ngModel)]="value"
		[modules]="modules"
		[bounds]="'self'"
		[placeholder]="placeholder">
	</quill-editor>
	<div class="validation-message" *ngIf="control?.touched && control.hasError('required')">{{ label }} is required</div>
</div>`,
	styleUrls: ['./html-editor.component.scss'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => HtmlEditorComponent), multi: true },
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => HtmlEditorComponent), multi: true }
	]
})
export class HtmlEditorComponent extends BaseComponent<string> implements OnChanges {
	private numChanges = 0;

	private editModules = {
		toolbar: [
			['bold', 'italic', 'underline'],        // toggled buttons
			[{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
			[{ 'list': 'ordered'}, { 'list': 'bullet' }],
			[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

			[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
			[{ 'header': [1, 2, 3, 4, 5, 6, false] }],

			[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
			[{ 'font': [] }],

			['link', 'image'] // link and image
		],
		history: {
			delay: 1000,
			maxStack: 500,
			userOnly: true
		}
	};
	@Input() placeholder = '';
	@Input() isReadOnly = false;

	@Output() firstChange = new EventEmitter<boolean>();
	@Output() valueSet = new EventEmitter<string>();

	identifier = `html-editor-${identifier++}`;

	get modules() {
		if (this.isReadOnly) {
			return {
				toolbar: false
			};
		}

		return this.editModules;
	}

	handleContentChanged(content: string) {
		this.numChanges++;
		switch (this.numChanges) {
			case 0:
				this.valueSet.emit(content);
				break;
			case 1:
				this.firstChange.emit(true);
				break;
			default:
				break;
		}
	}

	ngOnChanges() {
		/*

		We need to handle the conversion of the supplied HTML to the formatted value used by the quill editor.
		Setting the HTML can be slightly reformatted. E.g. setting plain text will then reformat it to be surrounded
		by HTML paragraph tags.

		The issue with this is that the content of the control will appear to have changed and mae the form dirty
		even when the user hasn't actually made a change. So to handle this, we record the number of changes and
		listen for the contentChanged event on the Quill editor, which will emit for the first time after the set
		content has been reformatted to its liking. This is then marked as "change 0" which emits a valueSet event.

		The first change (i.e. the user has actually changed the content for the first time) is emitted as a firstChange
		event.

		The valueSet event is most useful as the consuming form than mark its corresponding control as pristine
		thus solving the problem of the form appearing dirty when it's not.

		*/
		this.numChanges = -1;
		this.firstChange.emit(false);
	}
}
