import { Component, OnChanges, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { BaseComponent } from './base-component';
import { IRadioOption } from '../interfaces';

let identifier = 0;

@Component({
	selector: 'pp-radio-group',
	template: `
<div class="form-component" fxLayout="column">
	<span *ngIf="!labelHorizontal" class="label">{{ label }}</span>
	<div [fxLayout]="layout">
		<span fxFlex *ngIf="labelHorizontal" class="label-horizontal">{{ label }}</span>
		<mat-radio-group [(ngModel)]="value">
			<mat-radio-button
				[disabled]="isDisabled"
				*ngFor="let option of radioOptions"
				[value]="option.value">
				{{ option.name }}
			</mat-radio-button>
		</mat-radio-group>
	</div>
	<div class="validation-message" *ngIf="control?.touched && control.hasError('required')">{{ label }} is required</div>
</div>`,
	styleUrls: ['./radio-group.component.scss'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioGroupComponent), multi: true },
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => RadioGroupComponent), multi: true }
	]
})
export class RadioGroupComponent extends BaseComponent<number> implements OnChanges {
	radioOptions: IRadioOption[];

	@Input() label: string;
	@Input() labelHorizontal = false;
	@Input() layout = 'row';
	@Input() options: IRadioOption[];

	identifier = `radio-group-${identifier++}`;

	ngOnChanges(inputs) {
		if (inputs.options) {
			this.radioOptions = this.options.filter(o => !o.hide);
		}
	}
}
