import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, } from '@angular/forms';

import {
	ActivityHeaderEditLoadDto,
	ActivityHeaderEditDto,
	FormUtils
} from 'app/services';
import { IForm } from 'app/interfaces';

@Component({
	selector: 'pp-client-partner-edit',
	templateUrl: './client-partner-edit.component.html',
	styleUrls: ['./client-partner-edit.component.scss']
})
export class ClientPartnerEditComponent implements IForm {
	@Input() loadDto: ActivityHeaderEditLoadDto;
	@Input() editDto: ActivityHeaderEditDto;

	form: FormGroup;

	constructor(
		private fb: FormBuilder
	) {
		this.buildForm();
	}
	
	getForm(): FormGroup {
		return this.form;
	}

	private buildForm() {
		this.form = this.fb.group({
			clientPartnerFirstName: ['', null],
			clientPartnerLastName: ['', null]
		});
	}
}
