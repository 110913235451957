import { Inject, Directive, Input, EventEmitter, ElementRef, OnInit } from '@angular/core';

@Directive({
	selector: '[ppFocus]'
})
export class FocusDirective implements OnInit {
	/* tslint:disable-next-line:no-input-rename */
	@Input('ppFocus') focus: EventEmitter<boolean>;

	constructor(
		@Inject(ElementRef) private element: ElementRef) {
	}

	ngOnInit() {
		if (!this.focus) {
			return;
		}

		this.focus.subscribe(event => {
			setTimeout(() => {
				this.element.nativeElement.focus();
				this.element.nativeElement.select();
			}, 100);
		});
	}
}
