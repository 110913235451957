import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
	DashboardService,
	OpenInvoicesCountDto,
	CommonService,
} from 'app/services';

import { BaseViewComponent } from 'app/shared/base-view/base-view-component';
@Component({
	selector: 'pp-active-invoices',
	templateUrl: './active-invoices.component.html',
	styleUrls: ['./active-invoices.component.scss']
})
export class ActiveInvoicesComponent extends BaseViewComponent implements OnInit {
	invoices: OpenInvoicesCountDto;

	constructor(
		common: CommonService,
		private router: Router,
		private dashboardService: DashboardService
	) {
		super(common);
	}

	ngOnInit() {
		this.refresh();
	}

	refresh() {
		this.loading = true;
		this.dashboardService.getDashboardOpenInvoicesCount()
			.subscribe(result => this.handleDashboardData(result), err => this.handleError(err, 'loading invoices'));
	}

	private handleDashboardData(data: OpenInvoicesCountDto) {
		this.invoices = data;
		this.loading = false;
	}
}
